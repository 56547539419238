import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import Lightbox from 'react-image-lightbox';

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import people from "../../../images/pak-alif.png";
import section from "../../../images/portfolio/banner.jpg";
import damage1 from "../../../images/portfolio/boat-img-23.jpg";
import damage2 from "../../../images/portfolio/boat-img-24.jpg";
import damage3 from "../../../images/portfolio/boat-img-25.jpg";
import damage4 from "../../../images/portfolio/boat-img-26.jpg";
import damage5 from "../../../images/portfolio/boat-img-27.jpg";
import damage6 from "../../../images/portfolio/boat-img-28.jpg";
import damage7 from "../../../images/portfolio/boat-img-29.jpg";
import damage8 from "../../../images/portfolio/boat-img-31.jpg";
import damage9 from "../../../images/portfolio/boat-img-32.jpg";
import damage10 from "../../../images/portfolio/boat-img-33.jpg";
import damage11 from "../../../images/portfolio/boat-img-34.jpg";
import damage12 from "../../../images/portfolio/boat-img-35.jpg";
import damage13 from "../../../images/portfolio/boat-img-36.jpg";
import damage14 from "../../../images/portfolio/boat-img-37.jpg";
import damage15 from "../../../images/portfolio/boat-img-38.jpg";
import damage16 from "../../../images/portfolio/boat-img-39.jpg";
import damage17 from "../../../images/portfolio/boat-img-40.jpg";
import damage18 from "../../../images/portfolio/boat-img-41.jpg";
import damage19 from "../../../images/portfolio/boat-img-41.jpg";
import damage20 from "../../../images/portfolio/boat-img-41.jpg";
import damage21 from "../../../images/portfolio/boat-img-43.jpg";
import damage22 from "../../../images/portfolio/boat-img-44.jpg";
import damage23 from "../../../images/portfolio/boat-img-45.jpg";
import damage24 from "../../../images/portfolio/boat-img-46.jpg";
import damage25 from "../../../images/portfolio/boat-img-47.jpg";

import survey1 from "../../../images/portfolio/boat-img-1.jpg";
import survey2 from "../../../images/portfolio/boat-img-2-1.jpg";
import survey3 from "../../../images/portfolio/boat-img-3.jpg";
import survey4 from "../../../images/portfolio/boat-img-4.jpg";
import survey5 from "../../../images/portfolio/boat-img-5.jpg";
import survey6 from "../../../images/portfolio/boat-img-6.jpg";
import survey7 from "../../../images/portfolio/boat-img-7.jpg";
import survey8 from "../../../images/portfolio/boat-img-8.jpg";
import survey9 from "../../../images/portfolio/boat-img-9.jpg";
import survey10 from "../../../images/portfolio/boat-img-10.jpg";
import survey11 from "../../../images/portfolio/boat-img-11.jpg";
import survey12 from "../../../images/portfolio/boat-img-12.jpg";
import survey13 from "../../../images/portfolio/boat-img-13.jpg";
import survey14 from "../../../images/portfolio/boat-img-14.jpg";
import survey15 from "../../../images/portfolio/boat-img-15.jpg";
import survey16 from "../../../images/portfolio/boat-img-16.jpg";
import survey17 from "../../../images/portfolio/boat-img-17.jpg";
import survey18 from "../../../images/portfolio/boat-img-18.jpg";
import survey19 from "../../../images/portfolio/boat-img-19.jpg";
import survey20 from "../../../images/portfolio/boat-img-20.jpg";
import survey21 from "../../../images/portfolio/boat-img-21.jpg";
import survey22 from "../../../images/portfolio/boat-img-22.jpg";

require('dotenv').config();

class Portfolio extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            images: [photo1,photo2],
            photoIndex: 0,
            surveys: [
                {
                    image: survey1,
                    type: 'Supramax Bulk Carrier',
                    grt: '30,303',
                    year: '2020 ',
                },

                {
                    image: survey2,
                    type: 'Bulk Carrier',
                    grt: '30,303',
                    year: '2020',
                },

                {
                    image: survey3,
                    name: 'PT Samudera Marine Indonesia',
                    object: 'Shipyard, Facilities, and Management',
                    coverage: '80,000 sqm and 16,000 sqm',
                    year: '2008',
                },

                {
                    image: survey4,
                    type: 'Floating Production Storage and Offloading',
                    grt: '67,212',
                    year: '2011',
                },

                {
                    image: survey5,
                    object: 'Self Elevating Drilling Unit',
                    grt: '6,749',
                    year: '2011',
                },

                {
                    image: survey6,
                    type: 'Motor Tanker',
                    grt: '23,246',
                    year: '2020',
                },

                {
                    image: survey7,
                    type: 'Motor Tanker',
                    grt: '11,709',
                    year: '2020',
                },

                {
                    image: survey8,
                    type: 'Motor Tanker',
                    grt: '11,248',
                    year: '2020',
                },

                {
                    image: survey9,
                    type: 'Motor Tanker',
                    grt: '11,248',
                    year: '2020',
                },

                {
                    image: survey10,
                    type: 'Motor Tanker',
                    grt: '24,382',
                    year: '2020',
                },

                {
                    image: survey11,
                    type: 'Pleasure Yacht',
                    grt: '22',
                    year: '2020',
                },

                {
                    image:survey12,
                    type: 'Passenger Vessel',
                    grt: '6,022',
                    year: '2020',
                },

                {
                    image: survey13,
                    type: 'Passenger Vessel',
                    grt: '2,620',
                    year: '2021',
                },

                {
                    image: survey14,
                    type: 'Panamax Type Bulk Carrier',
                    grt: '40,230',
                    year: '2019',
                },

                {
                    image: survey15,
                    type: 'Motor Tanker',
                    grt: '23,244',
                    year: '2019',
                },

                {
                    image: survey16,
                    type: 'General Cargo',
                    grt: '23,401',
                    year: '2019',
                },

                {
                    image: survey17,
                    type: 'Crude Oil Tanker',
                    grt: '81,866',
                    year: '2018',
                },

                {
                    image: survey18,
                    type: 'Oil Products Tanker',
                    grt: '23,240',
                    year: '2018',
                },

                {
                    image: survey19,
                    type: 'Bulk Carrier',
                    grt: '21,220',
                    year: '2016',
                },

                {
                    image:survey20,
                    survey: 'Office Assessment to effectiveness of the management and applied system in the company, including: procedures, practices, and standards',
                    year: '2019',
                },

                {
                    image: survey21,
                    object: 'Floating Dock – Assessment to general condition of the shipyard, facilities, and management',
                    grt: '9,240',
                    year: '2020',
                },

                {
                    image: survey22,
                    type: 'Motorized Wooden Boat (pleasure boat)',
                    grt: '131',
                    year: '2018',
                },
            ],
            damages: [
                {
                    image: damage1,
                    desc: 'Vessel hit coral and sinking',
                    type: 'Bulk Carrier',
                    grt: '31,238',
                    year: '2011',
                },

                {
                    image: damage2,
                    desc: 'Barge leaked and capsized',
                    type: 'Barge leaked and capsized',
                    grt: '1,157',
                    year: '2006',
                },

                {
                    image: damage3,
                    desc: 'The vessel ran aground and subsequently came in collision with another grounded vessel',
                    type: 'Container Vessel',
                    grt: '66,332',
                    year: '2020',
                },

                {
                    image: damage4,
                    desc: 'The vessel stranded and struck coral cliff',
                    type: 'General Cargo Vessel',
                    grt: '709',
                    year: '2020',
                },

                {
                    image: damage5,
                    desc: 'Fire broke out – Wreck removal survey',
                    type: 'Passenger Vessel',
                    grt: '2,826',
                    year: '2020',
                },

                {
                    image: damage6,
                    desc: 'CPP Hub was found damaged',
                    type: 'Motor Vessel',
                    grt: '9,957',
                    year: '2016',
                },

                {
                    image: damage7,
                    desc: 'Jetty struck by drifted barge',
                    jetty: 'A protruding-constructed jetty owned by a manufacturer, used to deliver finished product and raw materials through pipeline and conveyor belt, each of which is installed at the different sides of the jetty.',
                },

                {
                    image: damage8,
                    desc: 'Jetty struck by loaded tanker whilst attempting to berth alongside the jetty',
                    jetty: 'A jetty with purpose of supply and deliver oil through piping system installed on the jetty.',
                },

                {
                    image: damage9,
                    desc: 'The vessel collided with other another vessel',
                    type: 'Container Vessel',
                    grt: '6,621',
                    year: '2017',
                },
                {
                    image: damage10,
                    desc: 'Fully loaded barge gradually listed',
                    type: 'Flat Top Barge',
                    grt: '5,225',
                    year: '2019',
                },

                {
                    image: damage11,
                    desc: 'Fully loaded vessel had engine trouble and subsequently hit rocky mountain',
                    type: 'Motor Vessel',
                    grt: '20,236',
                    year: '2011',
                },

                {
                    image: damage12,
                    desc: 'Tanker caught fire due to explosion in the engine room',
                    type: 'Motor Tanker',
                    grt: '3,663',
                    year: '2012',
                },

                {
                    image: damage13,
                    desc: 'Fully loaded vessel drifted due to rough weather and eventually stranded to shallow water',
                    type: 'Container Vessel',
                    grt: '3,668',
                    year: '2019',
                },

                {
                    image: damage14,
                    desc: 'Shipment of Submerged Soft Yoke System',
                    type: 'Heavy Lift Cargo supervision',
                    grt: '15,027',
                    year: '2018',
                },

                {
                    image: damage15,
                    desc: 'Vessel grounded and subsequently heavily listed to starboard',
                    type: 'General Cargo',
                    grt: '2,200',
                    year: '2017',
                },

                {
                    image: damage16,
                    desc: 'Loading cargo supervision',
                    type: 'General Cargo',
                    grt: '6,998',
                    year: '2005',

                },

                {
                    image: damage17,
                    desc: 'Vessel came In collision with another vessel',
                    type: 'Container Vessel',
                    grt: '4,632',
                    year: '2015',
                },

                {
                    image: damage18,
                    desc: 'Ferry took water and subsequently being semi-submerged in front of jetty.',
                    type: 'Passenger Vessel',
                    grt: '1,444',
                    year: '2020',
                },

                {
                    image: damage21,
                    desc: 'Vessel ran aground on the coral reef conservation area and being demanded for compensation by Ministry of Environment',
                    type: 'Pleasure Yacht',
                    grt: '1,140',
                    year: '2020',
                },

                {
                    image: damage22,
                    desc: 'Engine room caught on fire',
                    type: 'Floating Storage and Offloading (FSO) Tanker',
                    grt: '67,959',
                    year: '2011',
                },

                {
                    image: damage23,
                    desc: 'Vessel struck fuel supply wooden boat and oil pollution was sighted surround the wharf',
                    grt: '1,687',
                    year: '2013',
                },

                {
                    image: damage24,
                    desc: 'Tug took water and sank – Wreck removal survey',
                    type: 'Tug Boat',
                    grt: '56',
                    year: '2019',
                },

                {
                    image: damage25,
                    desc: 'Vessel caught by rough weather and dragged anchor, subsequently took water and sank in front of the wharf – Wreck removal survey',
                    type: 'General Cargo',
                    grt: '1,477',
                    year: '2016',
                },

            ]
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Portfolio';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service" style={{backgroundImage: `url(${section})`}}>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>SURVEY PORTFOLIO</h1>
                                    <p>Established in 2002, with more than 20 years of experience, Abadi Cemerlang has an extensive survey portfolio and encompasses a range of services and support for P&I Clubs, Hull and Machinery underwriters, shipowners, and other clients. </p>
                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-services">
                    <div className='container'>
                        <div className='title-wrap text-center'>
                            <h2>CONDITION SURVEY</h2>
                            <div className='border-line ml-auto'> </div>
                        </div>
                        <div className='row'>

                            {this.state.surveys.map((row,id) => (
                                <div className='col-md-4' key={id}>
                                    <div className='card-image port' style={{backgroundImage: `url(${row.image})`}}>

                                    </div>
                                    <div className='detail text-left align-items-end mb-3'>
                                        {row.type &&
                                        <label><strong>Type of Vessel: </strong> {row.type}</label>
                                        }
                                        {row.survey &&
                                        <label><strong>Type of Survey: </strong> {row.survey}</label>
                                        }
                                        {row.object &&
                                        <label><strong>Type of Object: </strong> {row.object}</label>
                                        }
                                        {row.grt &&
                                        <label><strong>GRT: </strong> {row.grt}</label>
                                        }
                                        {row.year &&
                                        <label><strong>Year of Survey: </strong> {row.year}</label>
                                        }
                                    </div>
                                </div>
                            ))}

                        </div>

                        <div className='title-wrap text-center'>
                            <h2>DAMAGE SURVEY AND APPROVAL SURVEY</h2>
                            <div className='border-line ml-auto'> </div>
                        </div>
                        <div className='row'>

                            {this.state.damages.map((row,id) => (
                                <div className='col-md-4' key={id}>
                                    <div className='card-image port' style={{backgroundImage: `url(${row.image})`}}>

                                    </div>
                                    <div className='detail text-left align-items-end mb-3'>
                                        {row.desc &&
                                        <label><strong>Brief Description: </strong> {row.desc}</label>
                                        }
                                        {row.type &&
                                        <label><strong>Type of Vessel: </strong> {row.type}</label>
                                        }
                                        {row.survey &&
                                        <label><strong>Type of Survey: </strong> {row.survey}</label>
                                        }
                                        {row.jetty &&
                                        <label><strong>Type Of Jetty: </strong> {row.jetty}</label>
                                        }
                                        {row.object &&
                                        <label><strong>Type of Object: </strong> {row.object}</label>
                                        }
                                        {row.grt &&
                                        <label><strong>GRT: </strong> {row.grt}</label>
                                        }
                                        {row.year &&
                                        <label><strong>Year of Survey: </strong> {row.year}</label>
                                        }
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </section>
            </div>


        )
    }
}

export default Portfolio;

