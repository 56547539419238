import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { history } from "../../../shared/configure-store";
import Lightbox from "react-image-lightbox";

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";

import one_a from "../../../images/pni/1/A/1.JPG";
import one_b from "../../../images/pni/1/B/1.JPG";
import two_a from "../../../images/pni/2/A/1.JPG";
import two_b from "../../../images/pni/2/B/1.JPG";
import two_c from "../../../images/pni/2/C/1.JPG";
import two_d from "../../../images/pni/2/D/1.JPG";
import two_e from "../../../images/pni/2/E/1.JPG";
import two_f from "../../../images/pni/2/F/1.JPG";
import two_g from "../../../images/pni/2/G/1.JPG";
import two_h from "../../../images/pni/2/H/1.JPG";
import two_i from "../../../images/pni/2/I/1.JPG";
import two_j from "../../../images/pni/2/J/1.jpg";
import two_k from "../../../images/pni/2/K/1.JPG";
import two_l from "../../../images/pni/2/L/1.JPG";
import two_m from "../../../images/pni/2/M/1.JPG";
import three_a from "../../../images/pni/3/1.JPG";
import fourth_a from "../../../images/pni/4/A/1.JPG";
import fourth_b from "../../../images/pni/4/B/1.jpg";
import fourth_c from "../../../images/pni/4/C/1.JPG";
import fourth_d from "../../../images/pni/4/D/1.JPG";
import five_a from "../../../images/pni/5/1.JPG";
import six_a from "../../../images/pni/6/A/1.JPG";
import six_b from "../../../images/pni/6/B/1.JPG";
import seven_a from "../../../images/pni/7/A/1.JPG";
import seven_b from "../../../images/pni/7/B/1.jpg";
import seven_c from "../../../images/pni/7/C/1.JPG";

//1A
import A2 from "../../../images/pni/1/A/2.JPG";
import A3 from "../../../images/pni/1/A/3.JPG";
import A4 from "../../../images/pni/1/A/4.JPG";
import A5 from "../../../images/pni/1/A/5.JPG";
import A6 from "../../../images/pni/1/A/6.JPG";
import A7 from "../../../images/pni/1/A/7.JPG";

//1B
import B2 from "../../../images/pni/1/B/2.JPG";
import B3 from "../../../images/pni/1/B/3.JPG";
import B4 from "../../../images/pni/1/B/4.JPG";
import B5 from "../../../images/pni/1/B/5.JPG";
import B6 from "../../../images/pni/1/B/6.JPG";
import B7 from "../../../images/pni/1/B/7.JPG";
import B8 from "../../../images/pni/1/B/8.JPG";
import B9 from "../../../images/pni/1/B/9.JPG";
import B10 from "../../../images/pni/1/B/10.JPG";
import B11 from "../../../images/pni/1/B/11.JPG";
import B12 from "../../../images/pni/1/B/12.JPG";
import B13 from "../../../images/pni/1/B/13.JPG";
import B14 from "../../../images/pni/1/B/14.JPG";
import B15 from "../../../images/pni/1/B/15.JPG";
import B16 from "../../../images/pni/1/B/16.JPG";
import B17 from "../../../images/pni/1/B/17.JPG";
import B18 from "../../../images/pni/1/B/18.JPG";
import B19 from "../../../images/pni/1/B/19.JPG";
import B20 from "../../../images/pni/1/B/20.jpg";
import B21 from "../../../images/pni/1/B/21.jpg";
import B22 from "../../../images/pni/1/B/22.jpg";
import B23 from "../../../images/pni/1/B/23.jpg";
import B24 from "../../../images/pni/1/B/24.jpg";
import B25 from "../../../images/pni/1/B/25.jpg";

//2A
import A2_2 from "../../../images/pni/2/A/2.JPG";
import A2_3 from "../../../images/pni/2/A/3.JPG";
import A2_4 from "../../../images/pni/2/A/4.JPG";
import A2_5 from "../../../images/pni/2/A/5.JPG";

//2B
import B2_2 from "../../../images/pni/2/B/2.JPG";
import B2_3 from "../../../images/pni/2/B/3.JPG";
import B2_4 from "../../../images/pni/2/B/4.JPG";
import B2_5 from "../../../images/pni/2/B/5.JPG";
import B2_6 from "../../../images/pni/2/B/6.JPG";

//2C
import C2_2 from "../../../images/pni/2/C/2.JPG";
import C2_3 from "../../../images/pni/2/C/3.JPG";
import C2_4 from "../../../images/pni/2/C/4.JPG";
import C2_5 from "../../../images/pni/2/C/5.JPG";
import C2_6 from "../../../images/pni/2/C/6.JPG";

//2D
import D2_2 from "../../../images/pni/2/D/2.JPG";
import D2_3 from "../../../images/pni/2/D/3.JPG";
import D2_4 from "../../../images/pni/2/D/4.JPG";
import D2_5 from "../../../images/pni/2/D/5.JPG";
import D2_6 from "../../../images/pni/2/D/6.JPG";
import D2_7 from "../../../images/pni/2/D/7.JPG";

//2E
import E2_2 from "../../../images/pni/2/E/2.JPG";
import E2_3 from "../../../images/pni/2/E/3.JPG";
import E2_4 from "../../../images/pni/2/E/4.JPG";
import E2_5 from "../../../images/pni/2/E/5.JPG";
import E2_6 from "../../../images/pni/2/E/6.JPG";
import E2_7 from "../../../images/pni/2/E/7.JPG";
import E2_8 from "../../../images/pni/2/E/8.JPG";

//2F
import F2_2 from "../../../images/pni/2/F/2.JPG";
import F2_3 from "../../../images/pni/2/F/3.JPG";
import F2_4 from "../../../images/pni/2/F/4.JPG";
import F2_5 from "../../../images/pni/2/F/5.JPG";
import F2_6 from "../../../images/pni/2/F/6.JPG";
import F2_7 from "../../../images/pni/2/F/7.jpg";

//2G
import G2_2 from "../../../images/pni/2/G/2.JPG";
import G2_3 from "../../../images/pni/2/G/3.JPG";
import G2_4 from "../../../images/pni/2/G/4.JPG";
import G2_5 from "../../../images/pni/2/G/5.JPG";

//2H
import H2_2 from "../../../images/pni/2/H/2.JPG";
import H2_3 from "../../../images/pni/2/H/3.JPG";
import H2_4 from "../../../images/pni/2/H/4.JPG";
import H2_5 from "../../../images/pni/2/H/5.JPG";
import H2_6 from "../../../images/pni/2/H/6.JPG";
import H2_7 from "../../../images/pni/2/H/7.JPG";
import H2_8 from "../../../images/pni/2/H/8.JPG";
import H2_9 from "../../../images/pni/2/H/9.JPG";
import H2_10 from "../../../images/pni/2/H/10.JPG";
import H2_11 from "../../../images/pni/2/H/11.JPG";
import H2_12 from "../../../images/pni/2/H/12.JPG";

//2i
import I2_2 from "../../../images/pni/2/I/2.JPG";
import I2_3 from "../../../images/pni/2/I/3.JPG";
import I2_4 from "../../../images/pni/2/I/4.JPG";
import I2_5 from "../../../images/pni/2/I/5.JPG";
import I2_6 from "../../../images/pni/2/I/6.JPG";
import I2_7 from "../../../images/pni/2/I/7.JPG";

//2J
import J2_2 from "../../../images/pni/2/J/2 (1).jpg";
import J2_55 from "../../../images/pni/2/J/2 (2).jpg";
import J2_3 from "../../../images/pni/2/J/2 (3).jpg";
import J2_4 from "../../../images/pni/2/J/2 (4).jpg";
import J2_5 from "../../../images/pni/2/J/2 (5).jpg";
import J2_6 from "../../../images/pni/2/J/2 (6).jpg";
import J2_7 from "../../../images/pni/2/J/2 (7).jpg";
import J2_8 from "../../../images/pni/2/J/2 (8).jpg";
import J2_9 from "../../../images/pni/2/J/2 (9).jpg";
import J2_10 from "../../../images/pni/2/J/2 (10).jpg";
import J2_11 from "../../../images/pni/2/J/2 (11).jpg";
import J2_12 from "../../../images/pni/2/J/2 (12).jpg";
import J2_13 from "../../../images/pni/2/J/2 (13).jpg";
import J2_14 from "../../../images/pni/2/J/2 (14).jpg";
import J2_15 from "../../../images/pni/2/J/2 (15).jpg";
import J2_16 from "../../../images/pni/2/J/2 (16).jpg";
import J2_17 from "../../../images/pni/2/J/2 (17).jpg";
import J2_18 from "../../../images/pni/2/J/2 (18).jpg";
import J2_19 from "../../../images/pni/2/J/2 (19).jpg";
import J2_20 from "../../../images/pni/2/J/2 (20).jpg";
import J2_21 from "../../../images/pni/2/J/2 (21).jpg";
import J2_22 from "../../../images/pni/2/J/2 (22).jpg";
import J2_23 from "../../../images/pni/2/J/2 (23).jpg";
import J2_24 from "../../../images/pni/2/J/2 (24).jpg";
import J2_25 from "../../../images/pni/2/J/2 (25).jpg";
import J2_26 from "../../../images/pni/2/J/2 (26).jpg";
import J2_27 from "../../../images/pni/2/J/2 (27).jpg";
import J2_28 from "../../../images/pni/2/J/2 (28).jpg";
import J2_29 from "../../../images/pni/2/J/2 (29).jpg";
import J2_30 from "../../../images/pni/2/J/2 (30).jpg";
import J2_31 from "../../../images/pni/2/J/2 (31).jpg";
import J2_32 from "../../../images/pni/2/J/2 (32).jpg";
import J2_33 from "../../../images/pni/2/J/2 (33).jpg";
import J2_34 from "../../../images/pni/2/J/2 (34).jpg";
import J2_35 from "../../../images/pni/2/J/2 (35).jpg";
import J2_36 from "../../../images/pni/2/J/2 (36).jpg";
import J2_37 from "../../../images/pni/2/J/2 (37).jpg";
import J2_38 from "../../../images/pni/2/J/2 (38).jpg";
import J2_39 from "../../../images/pni/2/J/2 (39).jpg";
import J2_40 from "../../../images/pni/2/J/2 (40).jpg";
import J2_41 from "../../../images/pni/2/J/2 (41).jpg";
import J2_42 from "../../../images/pni/2/J/2 (42).jpg";
import J2_43 from "../../../images/pni/2/J/2 (43).jpg";
import J2_44 from "../../../images/pni/2/J/2 (44).jpg";
import J2_45 from "../../../images/pni/2/J/2 (45).jpg";
import J2_46 from "../../../images/pni/2/J/2 (46).jpg";
import J2_47 from "../../../images/pni/2/J/2 (47).jpg";
import J2_48 from "../../../images/pni/2/J/2 (48).jpg";
import J2_49 from "../../../images/pni/2/J/2 (49).jpg";
import J2_50 from "../../../images/pni/2/J/2 (50).jpg";
import J2_51 from "../../../images/pni/2/J/2 (51).jpg";
import J2_52 from "../../../images/pni/2/J/2 (52).jpg";
import J2_53 from "../../../images/pni/2/J/2 (53).jpg";
import J2_54 from "../../../images/pni/2/J/2 (54).jpg";

//2K
import K2_2 from "../../../images/pni/2/K/2.JPG";

//2L
import L2_2 from "../../../images/pni/2/L/2 (1).JPG";
import L2_3 from "../../../images/pni/2/L/2 (2).JPG";
import L2_4 from "../../../images/pni/2/L/2 (3).JPG";
import L2_5 from "../../../images/pni/2/L/2 (4).JPG";
import L2_6 from "../../../images/pni/2/L/2 (5).JPG";
import L2_7 from "../../../images/pni/2/L/2 (6).jpg";
import L2_8 from "../../../images/pni/2/L/2 (7).jpg";
import L2_9 from "../../../images/pni/2/L/2 (8).jpg";
import L2_10 from "../../../images/pni/2/L/2 (9).jpg";
import L2_11 from "../../../images/pni/2/L/2 (10).jpg";

//2M
import M2_2 from "../../../images/pni/2/M/2.jpg";
import M2_3 from "../../../images/pni/2/M/3.JPG";
import M2_4 from "../../../images/pni/2/M/4.JPG";
import M2_5 from "../../../images/pni/2/M/5.JPG";
import M2_6 from "../../../images/pni/2/M/6.JPG";
import M2_7 from "../../../images/pni/2/M/7.JPG";

//3
import A3_2 from "../../../images/pni/3/2.JPG";
import A3_3 from "../../../images/pni/3/3.jpg";
import A3_4 from "../../../images/pni/3/4.JPG";
import A3_5 from "../../../images/pni/3/5.JPG";

//4A
import A4_2 from "../../../images/pni/4/A/2.JPG";
import A4_3 from "../../../images/pni/4/A/3.JPG";
import A4_4 from "../../../images/pni/4/A/4.JPG";
import A4_5 from "../../../images/pni/4/A/5.JPG";
import A4_6 from "../../../images/pni/4/A/6.JPG";
import A4_7 from "../../../images/pni/4/A/7.JPG";

//4B
import B4_2 from "../../../images/pni/4/B/2.jpg";
import B4_3 from "../../../images/pni/4/B/3.jpg";
import B4_4 from "../../../images/pni/4/B/4.jpg";
import B4_5 from "../../../images/pni/4/B/5.jpg";

//4C
import C4_2 from "../../../images/pni/4/C/2.JPG";
import C4_3 from "../../../images/pni/4/C/3.JPG";
import C4_4 from "../../../images/pni/4/C/4.JPG";
import C4_5 from "../../../images/pni/4/C/5.JPG";
import C4_6 from "../../../images/pni/4/C/6.JPG";
import C4_7 from "../../../images/pni/4/C/7.JPG";
import C4_8 from "../../../images/pni/4/C/8.JPG";
import C4_9 from "../../../images/pni/4/C/9.JPG";
import C4_10 from "../../../images/pni/4/C/10.JPG";
import C4_11 from "../../../images/pni/4/C/11.JPG";
import C4_12 from "../../../images/pni/4/C/12.JPG";
import C4_13 from "../../../images/pni/4/C/13.JPG";
import C4_14 from "../../../images/pni/4/C/14.JPG";
import C4_15 from "../../../images/pni/4/C/15.JPG";

//4D
import D4_2 from "../../../images/pni/4/D/2.jpg";
import D4_3 from "../../../images/pni/4/D/3.jpg";
import D4_4 from "../../../images/pni/4/D/4.JPG";
import D4_5 from "../../../images/pni/4/D/5.JPG";
import D4_6 from "../../../images/pni/4/D/6.JPG";
import D4_7 from "../../../images/pni/4/D/7.JPG";
import D4_8 from "../../../images/pni/4/D/8.jpg";
import D4_9 from "../../../images/pni/4/D/9.jpg";
import D4_10 from "../../../images/pni/4/D/10.jpg";

//5
import A5_2 from "../../../images/pni/5/2.JPG";
import A5_3 from "../../../images/pni/5/3.JPG";
import A5_4 from "../../../images/pni/5/4.JPG";
import A5_5 from "../../../images/pni/5/5.JPG";
import A5_6 from "../../../images/pni/5/6.JPG";
import A5_7 from "../../../images/pni/5/7.JPG";
import A5_8 from "../../../images/pni/5/8.JPG";
import A5_9 from "../../../images/pni/5/9.JPG";
import A5_10 from "../../../images/pni/5/10.JPG";
import A5_11 from "../../../images/pni/5/11.JPG";
import A5_12 from "../../../images/pni/5/12.JPG";
import A5_13 from "../../../images/pni/5/13.JPG";
import A5_14 from "../../../images/pni/5/14.JPG";

//6A
import A6_2 from "../../../images/pni/6/A/2.JPG";
import A6_3 from "../../../images/pni/6/A/3.JPG";
import A6_4 from "../../../images/pni/6/A/4.JPG";
import A6_5 from "../../../images/pni/6/A/5.JPG";
import A6_6 from "../../../images/pni/6/A/6.jpg";

//6B
import B6_2 from "../../../images/pni/6/B/2.JPG";
import B6_3 from "../../../images/pni/6/B/3.JPG";
import B6_4 from "../../../images/pni/6/B/4.JPG";
import B6_5 from "../../../images/pni/6/B/5.JPG";
import B6_6 from "../../../images/pni/6/B/6.JPG";
import B6_7 from "../../../images/pni/6/B/7.JPG";
import B6_8 from "../../../images/pni/6/B/8.JPG";
import B6_9 from "../../../images/pni/6/B/9.JPG";
import B6_10 from "../../../images/pni/6/B/10.JPG";

//7A
import A7_2 from "../../../images/pni/7/A/2.JPG";
import A7_3 from "../../../images/pni/7/A/3.JPG";
import A7_4 from "../../../images/pni/7/A/4.JPG";
import A7_5 from "../../../images/pni/7/A/5.JPG";
import A7_6 from "../../../images/pni/7/A/6.JPG";
import A7_7 from "../../../images/pni/7/A/7.JPG";

//7B
import B7_2 from "../../../images/pni/7/B/2.jpg";
import B7_3 from "../../../images/pni/7/B/3.jpg";
import B7_4 from "../../../images/pni/7/B/4.jpg";
import B7_5 from "../../../images/pni/7/B/5.jpg";
import B7_6 from "../../../images/pni/7/B/6.jpg";
import B7_7 from "../../../images/pni/7/B/7.jpg";
import B7_8 from "../../../images/pni/7/B/8.jpg";
import B7_9 from "../../../images/pni/7/B/9.jpg";
import B7_10 from "../../../images/pni/7/B/10.jpg";

//7C
import C7_2 from "../../../images/pni/7/C/2.JPG";
import C7_3 from "../../../images/pni/7/C/3.JPG";

require("dotenv").config();

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      images: [photo1, photo2],
      photoIndex: 0,
      services: [
        {
          title: "Pollution",
          sub: [
            {
              title: "TANKER – CAPSIZED AND POLLUTED THE PORT",
              image: one_a,
              images: [one_a, A2, , A3, A4, A5, A6, A7],
            },
            {
              title: "CONTAINER VESSEL – SUNK AND POLLUTED THE SEA",
              image: one_b,
              images: [
                one_b,
                B2,
                B3,
                B4,
                B5,
                B6,
                B7,
                B8,
                B9,
                B10,
                B11,
                B12,
                B13,
                B14,
                B15,
                B16,
                B17,
                B18,
                B19,
                B20,
                B21,
                B22,
                B23,
                B24,
                B25,
              ],
            },
          ],
        },
        {
          title: "Condition survey",
          sub: [
            {
              title:
                "CRUDE OIL TANKER – FULL CONDITION SURVEY ON BEHALF P&I CLUB",
              image: two_a,
              images: [two_a, A2_2, A2_3, A2_4, A2_5],
            },
            {
              title:
                "BULK CARRIER – FULL CONDITION SURVEY AND HATCH COVERS UT TIGHTNESS TEST",
              image: two_b,
              images: [two_b, B2_2, B2_3, B2_4, B2_5, B2_6],
            },
            {
              title:
                "BULK CARRIER – FULL CONDITION SURVEY AND HATCH COVERS UT TIGHTNESS TEST",
              image: two_c,
              images: [two_c, C2_2, C2_3, C2_4, C2_5, C2_6],
            },
            {
              title: "LNG CARRIER – FULL CONDITION SURVEY ON BEHALF P&I CLUB",
              image: two_d,
              images: [two_d, D2_2, D2_3, D2_4, D2_5, D2_6, D2_7],
            },
            {
              title:
                "CHEMICAL TANKER – FULL CONDITION SURVEY ON BEHALF P&I CLUB",
              image: two_e,
              images: [two_e, E2_2, E2_3, E2_4, E2_5, E2_6, E2_7, E2_8],
            },
            {
              title: "FPSO – CONDITION SURVEY AND VALUATION",
              image: two_f,
              images: [two_f, F2_2, F2_3, F2_4, F2_5, F2_6, F2_7],
            },
            {
              title: "PHINISI – CONDITION PRE PURCHASE SURVEY",
              image: two_g,
              images: [two_g, G2_2, G2_3, G2_4, G2_5],
            },
            {
              title:
                "PASSENGER VESSEL – CONDITION WRECK REMOVAL SURVEY ON BEHALF P&I CLUB",
              image: two_h,
              images: [
                two_h,
                H2_2,
                H2_3,
                H2_4,
                H2_5,
                H2_6,
                H2_7,
                H2_8,
                H2_9,
                H2_10,
                H2_11,
                H2_12,
              ],
            },
            {
              title: "PLEASURE YACHY – CONDTION PRE PURHCASE SURVEY",
              image: two_i,
              images: [two_i, I2_2, I2_3, I2_4, I2_5, I2_6, I2_7],
            },
            {
              title: "CAR CARRIER – CONDITION SURVEY",
              image: two_j,
              images: [
                two_j,
                J2_2,
                J2_55,
                J2_3,
                J2_4,
                J2_5,
                J2_6,
                J2_7,
                J2_8,
                J2_9,
                J2_10,
                J2_11,
                J2_12,
                J2_13,
                J2_14,
                J2_15,
                J2_16,
                J2_17,
                J2_18,
                J2_19,
                J2_20,
                J2_21,
                J2_22,
                J2_23,
                J2_24,
                J2_25,
                J2_26,
                J2_27,
                J2_28,
                J2_29,
                J2_30,
                J2_31,
                J2_32,
                J2_33,
                J2_34,
                J2_35,
                J2_36,
                J2_37,
                J2_38,
                J2_39,
                J2_40,
                J2_41,
                J2_42,
                J2_43,
                J2_44,
                J2_45,
                J2_46,
                J2_47,
                J2_48,
                J2_49,
                J2_50,
                J2_51,
                J2_52,
                J2_53,
                J2_54,
              ],
            },
            {
              title: "LCT – CONDITION AND VALUATION SURVEY",
              image: two_k,
              images: [two_k, K2_2],
            },
            {
              title:
                "FLOATING CRANE WORK BARGE – CONDITION AND PRE PURCHASE SURVEY",
              image: two_l,
              images: [
                two_l,
                L2_2,
                L2_3,
                L2_4,
                L2_5,
                L2_6,
                L2_7,
                L2_8,
                L2_9,
                L2_10,
                L2_11,
              ],
            },
            {
              title: "THSD – CONDITION AND PRE PURCHASE SURVEY",
              image: two_m,
              images: [two_m, M2_2, M2_3, M2_4, M2_5, M2_6, M2_7],
            },
          ],
        },
        {
          title: "Crew injury and repatriation",
          sub: [
            {
              title:
                "TUGBOAT – CREW EVACUATION AFTER THE TUG GROUNDED ON CORAL REEF",
              image: three_a,
              images: [three_a, A3_2, A3_3, A3_4, A3_5],
            },
          ],
        },
        {
          title: "Wreck removal",
          sub: [
            {
              title: "TUGBOAT – CAUGHT FIRE ON ENGINE ROOM",
              image: fourth_a,
              images: [fourth_a, A4_2, A4_3, A4_4, A4_5, A4_6, A4_7],
            },
            {
              title: "FLAT TOP BARGE – STRANDED AND PARTED IN THE MIDSHIP",
              image: fourth_b,
              images: [fourth_b, B4_2, B4_3, B4_4, B4_5],
            },
            {
              title: "FLAT TOP BARGE – STRANDED AND COMPLETELY PARTED",
              image: fourth_c,
              images: [
                fourth_c,
                C4_2,
                C4_3,
                C4_4,
                C4_5,
                C4_6,
                C4_7,
                C4_8,
                ,
                C4_9,
                C4_10,
                C4_11,
                C4_12,
                C4_13,
                C4_14,
                C4_15,
              ],
            },
            {
              title: "GENERAL CARGO – SEMI SUBMERGED AND TURNED INTO WRECK",
              image: fourth_d,
              images: [
                fourth_d,
                D4_2,
                D4_3,
                D4_4,
                D4_5,
                D4_6,
                D4_7,
                D4_8,
                D4_9,
                D4_10,
              ],
            },
          ],
        },
        {
          title: "UT Test Hatch Cover",
          sub: [
            {
              title:
                "BULK CARRIER – HATCH COVERS UT TIGHTNESS TEST ON BEHALF P&I CLUB",
              image: five_a,
              images: [
                five_a,
                A5_2,
                A5_3,
                A5_4,
                A5_5,
                A5_6,
                A5_7,
                A5_8,
                A5_9,
                A5_10,
                A5_11,
                A5_12,
                A5_13,
                A5_14,
              ],
            },
          ],
        },
        {
          title: "Cargo damage ",
          sub: [
            {
              title: "CONTAINER VESSEL – CONTAINERIZED CARGO EXPLODED",
              image: six_a,
              images: [six_a, A6_2, A6_3, A6_4, A6_5, A6_6],
            },
            {
              title: "BULK CARRIER – CARGO OF SOYBEAN DAMAGED BY SEAWATER",
              image: six_b,
              images: [
                six_b,
                B6_2,
                B6_3,
                B6_4,
                B6_5,
                B6_6,
                B6_7,
                B6_8,
                B6_9,
                B6_10,
              ],
            },
          ],
        },
        {
          title: "Third-party liability ",
          sub: [
            {
              title: "FLAT TOP BARGE – STRUCK JETTY TRESTLE",
              image: seven_a,
              images: [seven_a, A7_2, A7_3, A7_4, A7_5, A7_6, A7_7],
            },
            {
              title:
                "PLEASURE YACHT – RAN AGROUND AND DAMAGED THE RAJA AMPAT’S CORAL REEFS",
              image: seven_b,
              images: [
                seven_b,
                B7_2,
                B7_3,
                B7_4,
                B7_5,
                B7_6,
                B7_7,
                B7_8,
                B7_9,
                B7_10,
              ],
            },
            {
              title: "FLAT TOP BARGE – STRUCK VILLAGER’S PROPERTY",
              image: seven_c,
              images: [seven_c, C7_2, C7_3],
            },
          ],
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.title = "Abadi Cemerlang - Protection & Indemnity";
  }

  handleChange(e, prop) {
    this.setState({
      [prop]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({
      showDialog: false,
    });
  };

  handleGo = (link) => {
    history.push(link);
  };

  handleOpen = (item) => {
    this.setState({
      isOpen: true,
      imagesActive: item,
    });
  };

  render() {
    return (
      <div>
        <section className="section-banner service pni">
          <div className="container">
            <div className="row ">
              <div className="col-md-10">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                  <div className="content text-left">
                    <h1>Protection & Indemnity</h1>
                    <p>
                      Our extensive work with the IG P&I (Protection and
                      indemnity) Clubs and Fix Premium P&I clubs covers a wide
                      range of services and support, such as the prevention of
                      risks, third-party liabilities, and other surveys.
                    </p>
                    <p>
                      We have collaborated with various P&I Clubs throughout the
                      years, and we have experience in many cases in Indonesia
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="section-services">
          {this.state.services.map((row, id) => (
            <div className="container" key={id}>
              <div className="title-wrap mt-3 text-center text-uppercase">
                <h2>{row.title}</h2>
                <div className="border-line ml-auto"> </div>
              </div>

              <div className="row ">
                {row.sub.map(
                  function (item, idx) {
                    return (
                      <div
                        className="col-md-4"
                        key={idx}
                        onClick={() =>
                          this.handleOpen(item.images ? item.images : [])
                        }
                      >
                        <div
                          className="card-image mb-2"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                        <div className="detail box">
                          <label>{item.title}</label>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )}
              </div>
            </div>
          ))}

          {/*<div className='container'>*/}
          {/*    <div className='title-wrap text-center text-uppercase'>*/}
          {/*        <h2>Towage approval survey </h2>*/}
          {/*        <div className='border-line ml-auto'> </div>*/}
          {/*    </div>*/}

          {this.state.isOpen && (
            <Lightbox
              mainSrc={this.state.imagesActive[this.state.photoIndex]}
              nextSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + 1) % this.state.imagesActive.length
                ]
              }
              prevSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + this.state.imagesActive.length - 1) %
                    this.state.imagesActive.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.imagesActive.length -
                      1) %
                    this.state.imagesActive.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.imagesActive.length,
                })
              }
            />
          )}
          <AnimationOnScroll
            initiallyVisible={false}
            delay={500}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="row ">
              {this.state.services.map((row, id) => (
                <div className="col-md-4" key={id}>
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${row.image})` }}
                  >
                    <div className="detail box">
                      <h3>{row.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AnimationOnScroll>
          {/*</div>*/}
        </section>
      </div>
    );
  }
}

export default Services;
