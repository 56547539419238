import React,{Component} from 'react';
import image from "../../../images/error.png";

class NotFound extends Component {

    componentDidMount(){

        document.title = 'Abadi - 404 Not Found';

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title"></h1>

                    <div className='text-center mt-5'>
                        <h1 className="page-title">Halaman tidak ditemukan</h1>

                        <img src={image} alt='img-error'/>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;

