import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import section1 from "../../../images/section1.jpeg";
import section2 from "../../../images/section2.jpg";
import people from "../../../images/pak-alif.jpeg";
import service1 from "../../../images/home/1.JPG";
import service2 from "../../../images/home/2.jpg";
import service3 from "../../../images/home/3.JPG";
import service4 from "../../../images/home/4.JPG";
import service5 from "../../../images/home/5.JPG";
import service6 from "../../../images/home/6.jpeg";
import service7 from "../../../images/home/7.JPG";
import service8 from "../../../images/home/8.jpg";
import service9 from "../../../images/home/9.JPG";
import home from "../../../images/logo-big.png";

import SimpleReactValidator from "simple-react-validator";

require('dotenv').config();

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,

            videos: [],
            banners: [],
            galleries: [],
            services: [
                {
                    title: 'Hull & Machinery',
                    desc: 'Survey in relation to the physical condition (the hull and machinery) of the vessel. Hull & Machinery survey include but not limited to: collision, survey on connection with salvage of ship, vessel capsized, fire on board, vessel stranded, etc. ',
                    image: service1,
                },
                {
                    title: 'Protection & Indemnity',
                    desc: 'Survey in relation to the condition of cargo, crew injury and repatriation, third party liability, pollution, and wreck removal claims. ',
                    image: service2,
                },
                {
                    title: 'Valuation & Condition Survey',
                    desc: 'Valuation: A survey to ascertain the vessel\'s value through the entire condition of the vessel before being registered to underwriters or purchased by new owners. ',
                    image: service3,
                },
                {
                    title: 'Cargo Damage Survey',
                    desc: 'Where cargo damage has occurred and the cargo is insured, other than where it is felt to be the fault of the vessel, i.e. unseaworthiness, where it falls on the vessel’s P&I policy. Survey to the damaged cargo to ascertain the extent of damage, estimate cost of loss and cause of damage on behalf underwriter or charterer. ',
                    image: service4,
                },
                {
                    title: 'Heavy Cargo Loading Supervision',
                    desc: 'Inspection of the heavy-lift cargo condition before, during, and after the cargo loading process was carried out. The surveyor also inspected the stowage, securing, and sea-fastening before the shipment.',
                    image: service5,
                },
                {
                    title: 'Yacht & Phinisi Survey',
                    desc: 'A survey to ascertain the yacht/phinisi value through the entire condition of the yacht/phinisi before being purchased or a survey to ascertain the extent of damage, cost of repair and cause of damage to a yacht/phinisi after an accident.  ',
                    image: service6,
                },
                {
                    title: 'JH143 Surveys: Shipyard Risk Assessment Survey',
                    desc: 'A survey to provide underwriters with a thorough understanding of the nature of the repair yard risk and to provide risk management guidance to the yards.',
                    image: service7,
                },
                {
                    title: 'Towage Approval Survey',
                    desc: 'A survey to ascertain the condition, fitness and readiness of towing tug and an object to be towed. ',
                    image: service8,
                },
                {
                    title: 'Third Party Claim (Collision & RDC FFO)',
                    desc: 'A damage survey on basis of without prejudice on behalf of Club or underwriters to the opponent\'s as a result of an incident.',
                    image: service9,
                },
            ],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Home';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner home">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-10 col-lg-8'>
                                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <div className='content'>
                                    <h1 className='text-white'>PT. ABADI CEMERLANG</h1>
                                    <h1 className='text-blue'>Marine Surveyor & Consultant</h1>
                                    <p className='text-blue'>We support the industry with professional representation to Clients for incident investigations, expert witness, and marine survey inspections. </p>

                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section1">
                    <div className='container'>
                        <div className='row align-items-center flex-md-row-reverse'>
                            <div className='col-md-6'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeIn" >
                                    <div className='img-wrap'>
                                        <img src={section1} className='img-fluid' />
                                    </div>

                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-6'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeInUp">
                                <h2>WHO WE ARE</h2>
                                </AnimationOnScroll>

                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} delay={500} duration={4} animateIn="animate__fadeIn" >
                                <p>Abadi Cemerlang is recognized in Indonesia insurance market as one of the leading independent Marine Surveyor and Consultants for more than 20 years served as a member of the P&I Club and also the representative for overseas underwriters.</p>
                                <p>Abadi Cemerlang has been engaged to examine various incidents and disputes including fatality investigations, large-scale equipment damage and pollution claims, and contractual disputes in the Insurance market.</p>

                                {/*<p>Maritime incident investigations involve a complex interaction of many causes. Identifying these causes requires consideration, not only of the clear information gleaned from reports and witness statements, photographs, and wreckage, but also the impact of aspects of the organization of work, and human factors such as perception, motivation, and behavior. The task of a Marine Surveyor involved in an incident investigation is to determine what these factors and faults are and the links between them.</p>*/}

                                <p>There are many examples of cases being lost for simple reasons, which may occur as a result of an inadequate investigation undertaken, significant evidence overlooked, and unsubstantiated causes of incident suggested.</p>

                                <p>At Abadi Cemerlang, we ensure a robust and professional investigation and concise report that will withstand external review</p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section2 section-services">
                    <div className='container'>
                        <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn="animate__fadeInRight" >
                            <div className='text-center'>
                                <p className='mb-2'>CONDITION & DAMAGE SURVEYS</p>
                                <h2>OUR SERVICES</h2>
                                <p>We provide pragmatic technical advice to industries on maritime projects. <br />to successfully manage processes from ship problems like claim handling for insurance.</p>
                            </div>
                        </AnimationOnScroll>

                        <AnimationOnScroll initiallyVisible={false} delay={500} animateOnce={true} animateIn="animate__fadeIn" >
                            <div className='row '>
                                {this.state.services.map((row,id) => (
                                    <div className='col-md-4' key={id}>
                                        <div className='card-image' style={{backgroundImage: `url(${row.image})`}}>
                                            <div className='detail'>
                                                <h3>{row.title}</h3>
                                                <p>{row.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </AnimationOnScroll>

                    </div>
                </section>
                <section className="section3">
                    <div className='container'>
                        <div className='row align-items-center'>

                            <div className='col-md-5'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeIn" >
                                <div className='img-wrap'>
                                    <img src={people} className='img-fluid' />

                                </div>
                                <p className='mb-0 mt-3'>Alif Hastono</p>
                                <small>Director / Principal Surveyor</small>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-7 text-md-right'>
                                <AnimationOnScroll initiallyVisible={false} delay={500} animateOnce={true} animateIn="animate__fadeIn" >
                                <h2>THE A-TEAM</h2>

                                <blockquote>"Every project is unique in the resources it requires. In order to deliver successful results we adopt a flexible manning arrangement and apply personnel where necessary."</blockquote>

                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9184359831665!2d106.72336331543245!3d-6.274454895458601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f0764c2ed52d%3A0xe88d52e3c636702a!2sPT%20Abadi%20Cemerlang!5e0!3m2!1sid!2sid!4v1588624271359!5m2!1sid!2sid"
                    width="100%" height="250" frameBorder="0" allowFullScreen="" aria-hidden="false"
                    tabIndex="0">

                </iframe>

                </section>
            </div>

        )
    }
}

export default Home;
