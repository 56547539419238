import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './shared/configure-store'

import NotFound from "./components/container/not-found"
import { PrivateRoute, RedirectRoute } from './libraries/route-handling'
import Navbar from "./components/container/navbar";
import Footer from "./components/container/footer";
import Home from "./components/container/homes";
import Services from "./components/container/services";
import HnM from "./components/container/hnm";
import MarineCargo from "./components/container/marine-cargo";
import PnI from "./components/container/pni";
import About from "./components/container/about";
import Teams from "./components/container/teams";
import Contact from "./components/container/contact";
import Client from "./components/container/client";
import Portfolio from "./components/container/portfolio";

const Routes = ({store}) => (

    <Provider store={store}>

        <ConnectedRouter history={history}>
            <Navbar />
            <main>
                {

                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/our-services" component={Services}/>
                        <Route exact path="/hull-machinery" component={HnM}/>
                        <Route exact path="/marine-cargo" component={MarineCargo}/>
                        <Route exact path="/pni" component={PnI}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/our-teams" component={Teams}/>
                        <Route exact path="/contact-us" component={Contact}/>
                        <Route exact path="/clients" component={Client}/>
                        <Route exact path="/portfolio" component={Portfolio}/>

                        <Route component={NotFound}/>

                    </Switch>

                }
            </main>
            <Footer />

        </ConnectedRouter>

    </Provider>

);

export default Routes;