import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { history } from "../../../shared/configure-store";
import Lightbox from "react-image-lightbox";

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import people from "../../../images/pak-alif.png";
import service1 from "../../../images/home/1.JPG";
import service2 from "../../../images/home/2.jpg";
import service3 from "../../../images/home/3.JPG";
import service4 from "../../../images/home/4.JPG";
import service5 from "../../../images/home/5.JPG";
import service6 from "../../../images/home/6.jpeg";
import service7 from "../../../images/home/7.JPG";
import service8 from "../../../images/home/8.jpg";
import service9 from "../../../images/home/9.JPG";

import one_a from "../../../images/services/1/A/1.jpg";
import one_b from "../../../images/services/1/B/1.png";
import one_c from "../../../images/services/1/C/1.jpg";
import two_a from "../../../images/services/2/A/1.JPG";
import two_b from "../../../images/services/2/B/1.JPG";
import three_a from "../../../images/services/3/A/1.JPG";
import three_b from "../../../images/services/3/B/1.JPG";
import four_a from "../../../images/services/4/A/1.jpg";
import four_b from "../../../images/services/4/B/1.JPG";
import four_c from "../../../images/services/4/C/1.JPG";
import four_d from "../../../images/services/4/D/1.png";
import four_e from "../../../images/services/4/E/1.jpg";
import four_f from "../../../images/services/4/F/1.JPG";
import five_a from "../../../images/services/5/1.JPG";
import six_a from "../../../images/services/6/1.JPG";
import seven_a from "../../../images/services/7/1.png";
import eight_a from "../../../images/services/8/A/1.JPG";
import eight_b from "../../../images/services/8/B/1.png";
import nine_a from "../../../images/services/9/A/1.jpg";
import ten_a from "../../../images/services/10/1.jpeg";
import eleven_a from "../../../images/services/11/1.JPG";
import twelve_a from "../../../images/services/12/A/1.JPG";
import twelve_b from "../../../images/services/12/B/1.JPG";
import thirteen_a from "../../../images/services/13/A/1.JPG";
import thirteen_b from "../../../images/services/13/B/1.JPG";
import thirteen_c from "../../../images/services/13/C/1.JPG";
import fourteen_a from "../../../images/services/14/A/1.jpeg";
import fourteen_b from "../../../images/services/14/B/1.jpg";

//1A
import include1a1 from "../../../images/services/1/A/1.jpg";
import include1a2 from "../../../images/services/1/A/0039.jpg";
import include1a3 from "../../../images/services/1/A/0052.jpg";
import include1a4 from "../../../images/services/1/A/0068.jpg";
import include1a5 from "../../../images/services/1/A/0080.jpg";
import include1a6 from "../../../images/services/1/A/0081.jpg";

//1B
import include1b1 from "../../../images/services/1/B/Andrina.png";
import include1b2 from "../../../images/services/1/B/Andrina 3.png";
import include1b3 from "../../../images/services/1/B/Andrina 4.png";
import include1b4 from "../../../images/services/1/B/Andrina 5.png";
import include1b5 from "../../../images/services/1/B/Andrina 6.png";

//1C
import include1c1 from "../../../images/services/1/C/1.jpg";
import include1c2 from "../../../images/services/1/C/2.jpg";
import include1c3 from "../../../images/services/1/C/3.jpg";
import include1c4 from "../../../images/services/1/C/4.jpg";
import include1c5 from "../../../images/services/1/C/5.jpg";
import include1c6 from "../../../images/services/1/C/6.jpg";
import include1c7 from "../../../images/services/1/C/7.jpg";
import include1c8 from "../../../images/services/1/C/8.jpg";
import include1c9 from "../../../images/services/1/C/9.jpg";

//2A
import include2a2 from "../../../images/services/2/A/2.JPG";
import include2a3 from "../../../images/services/2/A/3.JPG";

//2B
import include2b2 from "../../../images/services/2/B/2.JPG";
import include2b3 from "../../../images/services/2/B/3.JPG";
import include2b4 from "../../../images/services/2/B/4.JPG";

//3A
import include3a2 from "../../../images/services/3/A/2.JPG";
import include3a3 from "../../../images/services/3/A/3.JPG";
import include3a4 from "../../../images/services/3/A/4.JPG";
import include3a5 from "../../../images/services/3/A/5.JPG";
import include3a6 from "../../../images/services/3/A/6.JPG";
import include3a7 from "../../../images/services/3/A/7.JPG";

//3B
import include3b2 from "../../../images/services/3/B/2.JPG";
import include3b3 from "../../../images/services/3/B/3.JPG";
import include3b4 from "../../../images/services/3/B/4.JPG";

//4A
import include4a2 from "../../../images/services/4/A/2.jpg";
import include4a3 from "../../../images/services/4/A/3.jpg";
import include4a4 from "../../../images/services/4/A/4.jpg";
import include4a5 from "../../../images/services/4/A/5.jpg";
import include4a6 from "../../../images/services/4/A/6.jpg";
import include4a7 from "../../../images/services/4/A/7.jpg";
import include4a8 from "../../../images/services/4/A/8.jpg";

//4B
import include4b2 from "../../../images/services/4/B/2.JPG";
import include4b3 from "../../../images/services/4/B/3.JPG";
import include4b4 from "../../../images/services/4/B/4.JPG";
import include4b5 from "../../../images/services/4/B/5.JPG";

//4C
import include4c2 from "../../../images/services/4/C/2.JPG";
import include4c3 from "../../../images/services/4/C/3.JPG";
import include4c4 from "../../../images/services/4/C/4.JPG";
import include4c5 from "../../../images/services/4/C/5.JPG";
import include4c6 from "../../../images/services/4/C/6.JPG";
import include4c7 from "../../../images/services/4/C/7.JPG";
import include4c8 from "../../../images/services/4/C/8.JPG";

//4D
import include4d2 from "../../../images/services/4/D/2.png";
import include4d3 from "../../../images/services/4/D/3.png";
import include4d4 from "../../../images/services/4/D/4.png";
import include4d5 from "../../../images/services/4/D/5.png";

//4E
import include4e2 from "../../../images/services/4/E/2.jpg";
import include4e3 from "../../../images/services/4/E/3.jpg";
import include4e4 from "../../../images/services/4/E/4.JPG";

//4F ,include f6 dan f7 belum dimasukan,konfirmasi terlebih dahulu
import include4f2 from "../../../images/services/4/F/2.JPG";
import include4f3 from "../../../images/services/4/F/3.JPG";
import include4f4 from "../../../images/services/4/F/4.JPG";
import include4f5 from "../../../images/services/4/F/5.JPG";
import include4f6 from "../../../images/services/4/F/6.JPG";
import include4f7 from "../../../images/services/4/F/7.JPG";
import include4f8 from "../../../images/services/4/F/8.JPG";
import include4f9 from "../../../images/services/4/F/9.JPG";
import include4f10 from "../../../images/services/4/F/10.JPG";

//5
import include5a from "../../../images/services/5/2.JPG";
import include5b from "../../../images/services/5/3.JPG";
import include5c from "../../../images/services/5/4.JPG";
import include5d from "../../../images/services/5/5.JPG";
import include5e from "../../../images/services/5/6.JPG";

//6
import include6a from "../../../images/services/6/2.JPG";

//7
import include71 from "../../../images/services/7/1.png";
import include72 from "../../../images/services/7/2.jpg";
import include73 from "../../../images/services/7/3.jpg";
import include74 from "../../../images/services/7/4.jpg";
import include75 from "../../../images/services/7/5.jpg";
import include76 from "../../../images/services/7/6.jpeg";
import include77 from "../../../images/services/7/7.jpeg";
import include78 from "../../../images/services/7/8.jpg";
import include79 from "../../../images/services/7/9.jpg";
import include710 from "../../../images/services/7/10.jpg";
import include711 from "../../../images/services/7/11.jpg";
import include712 from "../../../images/services/7/12.jpg";
import include713 from "../../../images/services/7/13.jpg";
import include714 from "../../../images/services/7/14.jpg";
import include715 from "../../../images/services/7/15.jpg";
import include716 from "../../../images/services/7/16.jpg";
import include717 from "../../../images/services/7/17.jpg";
import include718 from "../../../images/services/7/18.jpg";
import include719 from "../../../images/services/7/19.jpg";
import include720 from "../../../images/services/7/20.jpg";
import include721 from "../../../images/services/7/21.jpg";
import include722 from "../../../images/services/7/22.jpg";
import include723 from "../../../images/services/7/23.jpg";
import include724 from "../../../images/services/7/24.jpg";
import include725 from "../../../images/services/7/25.jpg";
import include726 from "../../../images/services/7/26.jpg";
import include727 from "../../../images/services/7/27.jpg";
import include728 from "../../../images/services/7/28.jpg";
import include729 from "../../../images/services/7/29.jpg";
import include730 from "../../../images/services/7/30.jpg";
import include731 from "../../../images/services/7/31.jpg";
import include732 from "../../../images/services/7/32.jpg";
import include733 from "../../../images/services/7/33.jpg";
import include734 from "../../../images/services/7/34.jpg";
import include735 from "../../../images/services/7/35.jpg";
import include736 from "../../../images/services/7/36.jpg";
import include737 from "../../../images/services/7/37.jpg";
import include738 from "../../../images/services/7/38.jpg";
import include739 from "../../../images/services/7/39.jpg";
import include740 from "../../../images/services/7/40.jpg";
import include741 from "../../../images/services/7/41.jpg";
import include742 from "../../../images/services/7/42.jpg";
import include743 from "../../../images/services/7/43.jpg";
import include744 from "../../../images/services/7/44.jpg";
import include745 from "../../../images/services/7/45.jpg";
import include746 from "../../../images/services/7/46.jpg";
import include747 from "../../../images/services/7/47.jpg";
import include748 from "../../../images/services/7/48.jpg";
import include749 from "../../../images/services/7/49.jpg";
import include750 from "../../../images/services/7/50.jpg";
import include751 from "../../../images/services/7/51.jpg";
import include752 from "../../../images/services/7/52.jpg";
import include753 from "../../../images/services/7/53.jpg";
import include754 from "../../../images/services/7/54.jpg";
import include755 from "../../../images/services/7/55.jpg";
import include756 from "../../../images/services/7/56.jpg";
import include757 from "../../../images/services/7/57.jpg";
import include758 from "../../../images/services/7/58.jpg";
import include759 from "../../../images/services/7/59.jpg";
import include760 from "../../../images/services/7/60.jpg";
import include761 from "../../../images/services/7/61.jpeg";
import include762 from "../../../images/services/7/62.jpeg";
import include763 from "../../../images/services/7/63.jpeg";

//8
import include8a from "../../../images/services/8/A/1.JPG";
import include8b from "../../../images/services/8/B/1.png";
import include8c from "../../../images/services/8/3.JPG";
import include8d from "../../../images/services/8/4.JPG";
import include8e from "../../../images/services/8/5.JPG";
import include8f from "../../../images/services/8/6.JPG";
import include8g from "../../../images/services/8/7.JPG";
import include8h from "../../../images/services/8/8.JPG";

//9A
import include9a from "../../../images/services/9/A/2.jpg";
import include9b from "../../../images/services/9/A/3.jpg";
import include9c from "../../../images/services/9/A/9.jpg";
import include9d from "../../../images/services/9/A/5.jpg";
import include9e from "../../../images/services/9/A/6.jpg";
import include9f from "../../../images/services/9/A/7.jpg";
import include9g from "../../../images/services/9/A/8.jpg";

import include102 from "../../../images/services/10/2.jpeg";
import include103 from "../../../images/services/10/3.jpeg";
import include104 from "../../../images/services/10/4.jpeg";
import include105 from "../../../images/services/10/5.jpeg";
import include106 from "../../../images/services/10/6.jpeg";
import include107 from "../../../images/services/10/7.jpeg";
import include108 from "../../../images/services/10/8.jpeg";
import include109 from "../../../images/services/10/9.jpeg";
import include1010 from "../../../images/services/10/10.jpeg";
import include1011 from "../../../images/services/10/11.jpeg";
import include1012 from "../../../images/services/10/12.jpeg";
import include1013 from "../../../images/services/10/13.jpeg";
import include1014 from "../../../images/services/10/14.jpeg";
import include1015 from "../../../images/services/10/15.jpeg";
import include1016 from "../../../images/services/10/16.jpeg";
import include1017 from "../../../images/services/10/17.jpeg";
import include1018 from "../../../images/services/10/18.jpeg";
import include1019 from "../../../images/services/10/19.jpeg";
import include1020 from "../../../images/services/10/20.jpeg";
import include1021 from "../../../images/services/10/21.jpeg";

//11
import include111 from "../../../images/services/11/2.JPG";
import include112 from "../../../images/services/11/3.JPG";

//12A
import include121 from "../../../images/services/12/A/2.jpg";
import include122 from "../../../images/services/12/A/3.jpg";
import include123 from "../../../images/services/12/A/4.jpg";
import include124 from "../../../images/services/12/A/5.JPG";

//12B
import include12B1 from "../../../images/services/12/B/2.JPG";
import include12B2 from "../../../images/services/12/B/3.JPG";
import include12B3 from "../../../images/services/12/B/4.JPG";
import include12B4 from "../../../images/services/12/B/5.JPG";

//13A
import include13A1 from "../../../images/services/13/A/2.jpg";
import include13A2 from "../../../images/services/13/A/3.JPG";
import include13A3 from "../../../images/services/13/A/4.JPG";
import include13A4 from "../../../images/services/13/A/5.jpg";
//13B
import include13B1 from "../../../images/services/13/B/2.JPG";
import include13B2 from "../../../images/services/13/B/3.JPG";
import include13B3 from "../../../images/services/13/B/4.JPG";
//13C
import include13C1 from "../../../images/services/13/C/2.JPG";
import include13C2 from "../../../images/services/13/C/3.JPG";
import include13C3 from "../../../images/services/13/C/4.JPG";
import include13C4 from "../../../images/services/13/C/5.JPG";
import include13C5 from "../../../images/services/13/C/6.JPG";

//14A

import include142 from "../../../images/services/14/A/2.jpeg";

require("dotenv").config();

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      images: [photo1, photo2],
      imagesActive: [],
      photoIndex: 0,
      services: [
        {
          title: "Towage approval survey",
          sub: [
            {
              title: "HARBOUR CRANE – TOWAGE APPROVAL SURVEY",
              image: one_a,
              images: [
                include1a1,
                include1a2,
                include1a3,
                include1a4,
                include1a5,
                include1a6,
              ],
            },
            {
              title:
                "DISABLED OIL TANKER TOWAGE APPROVAL SURVEY TO THE REPAIR YARD",
              image: one_b,
              images: [
                one_b,
                include1b1,
                include1b2,
                include1b3,
                include1b4,
                include1b5,
              ],
            },
            {
              title: "TRANSLOADER FLOATING CRANE – TOWAGE APPROVAL SURVEY",
              image: one_c,
              images: [
                one_c,
                include1c1,
                include1c2,
                include1c3,
                include1c4,
                include1c5,
                include1c6,
                include1c7,
                include1c8,
                include1c9,
              ],
            },
          ],
        },
        {
          title: "Prepurchase Condition survey / Valuation survey ",
          sub: [
            {
              title: "OIL CHEMICAL TANKER – CONDITION & VALUATION SURVEY",
              image: two_a,
              images: [two_a, include2a2, include2a3],
            },
            {
              title: "PHINISI – CONDITION & VALUATION SURVEY",
              image: two_b,
              images: [two_b, include2b2, include2b3, include2b4],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "JH143 Shipyard risk Assessment",
          sub: [
            {
              title:
                "FLOATING DOCK – JH143 SHIPYARD ASSESSMENT AND SHIP REPAIRER LIABILITY SURVEY",
              image: three_a,
              images: [
                three_a,
                include3a2,
                include3a3,
                include3a4,
                include3a5,
                include3a6,
                include3a7,
              ],
            },
            {
              title: "SHIPYARD – JH143 SHIPYARD ASSESSMENT",
              image: three_b,
              images: [three_b, include3b2, include3b3, include3b4],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Survey for shipment of material and Heavy Equipment",
          sub: [
            {
              title: "GENERAL CARGO - SOFT YOKE SYSTEM PRESHIPMENT SURVEY",
              image: four_a,
              images: [
                four_a,
                include4a2,
                include4a3,
                include4a4,
                include4a5,
                include4a6,
                include4a7,
                include4a8,
              ],
            },
            {
              title:
                "FLAT TOP BARGE – POWER PLANT EQUIPMENT PRESHIPMENT SURVEY",
              image: four_b,
              images: [four_b, include4b2, include4b3, include4b4, include4b5],
            },
            {
              title: "GENERAL CARGO – STAINLESS STEEL COILS PRESHIPMENT SURVEY",
              image: four_c,
              images: [
                four_a,
                include4c2,
                include4c3,
                include4c4,
                include4c5,
                include4c6,
                include4c7,
                include4c8,
              ],
            },
            {
              title: "GENERAL CARGO – GENERAL CARGO DISCHARGING SURVEY",
              image: four_d,
              images: [four_d, include4d2, include4d3, include4d4, include4d5],
            },
            {
              title:
                "BULK CARRIER – CARGO COAL LOADING ACTIVITY SUPERVISION SURVEY",
              image: four_e,
              images: [four_e, include4e2, include4e3, include4e4],
            },
            {
              title:
                "BULK CARRIER – CARGO COAL LOADING ACTIVITY SUPERVISION SURVEY",
              image: four_f,
              images: [
                four_f,
                include4f2,
                include4f3,
                include4f4,
                include4f5,
                include4f6,
                include4f7,
                include4f8,
                include4f9,
                include4f10,
              ],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Damage survey in connection with Ship Repairer Liability",
          sub: [
            {
              title:
                "SHIPYARD SRL DAMAGE SURVEY – FIRE BROKE OUT ON A DREDGER BARGE DURING REPAIR IN DRY DOCK",
              image: five_a,
              images: [
                five_a,
                include5a,
                include5b,
                include5c,
                include5d,
                include5e,
              ],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Condition survey in connection with mortgage",
          sub: [
            {
              title: "LCT – CONDITION SURVEY IN CONNECTION WITH MORTGAGE",
              image: six_a,
              images: [six_a, include6a],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Cleanliness survey",
          sub: [
            {
              title:
                "GENERAL CARGO – CARGO HOLD CLEANLINESS SURVEY IN CONNECTION WITH PORT REQUIRMENT",
              image: seven_a,
              images: [
                seven_a,
                include71,
                include72,
                include73,
                include74,
                include75,
                include76,
                include77,
                include78,
                include79,
                include710,
                include711,
                include712,
                include713,
                include714,
                include715,
                include716,
                include717,
                include718,
                include719,
                include720,
                include721,
                include722,
                include723,
                include724,
                include725,
                include726,
                include727,
                include728,
                include729,
                include730,
                include731,
                include732,
                include733,
                include734,
                include735,
                include736,
                include737,
                include738,
                include739,
                include740,
                include741,
                include742,
                include743,
                include744,
                include745,
                include746,
                include747,
                include748,
                include749,
                include750,
                include751,
                include752,
                include753,
                include754,
                include755,
                include756,
                include757,
                include758,
                include759,
                include760,
                include761,
                include762,
                include763,
              ],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Underwater Survey",
          sub: [
            {
              title: "UNDERWATER SURVEY IN CONNECTION WITH GROUNDING INCIDENT",
              image: eight_a,
              images: [
                eight_a,
                include8a,
                include8b,
                include8c,
                include8d,
                include8e,
                include8f,
                include8g,
                include8h,
              ],
            },
            {
              title: "UNDERWATER SURVEY PROPELLER AND SHAFT PARTED AND LOST",
              image: eight_b,
              images: [
                eight_b,
                include8c,
                include8d,
                include8e,
                include8f,
                include8g,
                include8h,
              ],
            },
          ],
          images: [photo2, photo1],
        },
        {
          title: "Draft Survey",
          sub: [
            {
              title:
                "BULK CARRIER – CARGO OF SOYBEAN MEAL DISCHARGE MONITORING AND DRAFT SURVEY",
              image: nine_a,
              images: [
                nine_a,
                include9a,
                include9b,
                include9c,
                include9d,
                include9e,
                include9f,
                include9g,
              ],
            },
          ],
        },
        {
          title: "Bunker Survey",
          sub: [
            {
              title:
                "CEMENT CARRIER – BUNKER SURVEY IN CONNECTION WITH CHARTERER INTEREST",
              image: ten_a,
              images: [
                ten_a,
                include102,
                include103,
                include104,
                include105,
                include106,
                include107,
                include108,
                include109,
                include1010,
                include1011,
                include1012,
                include1013,
                include1014,
                include1015,
                include1016,
                include1017,
                include1018,
                include1019,
                include1020,
                include1021,
              ],
            },
          ],
        },
        {
          title: "Site lay-up / reactivation survey ",
          sub: [
            {
              title:
                "PLEASURE BOAT – REACTIVATION CONDITION SURVEY AFTER BEING IDLE FOR 2 YEARS",
              image: eleven_a,
              images: [eleven_a, include111, include112],
            },
          ],
        },
        {
          title: "Risk assessment Survey",
          sub: [
            {
              title:
                "RISK ASSESSMENT SURVEY TO STEVEDORING ACTIVITY OF COAL AT ACEH",
              image: twelve_a,
              images: [twelve_a, include121, include122, include123],
            },
            {
              title:
                "RISK ASSEMENT TO THE POTENTIAL RISK DURING LAND DELIVERY OF CARGO",
              image: twelve_b,
              images: [twelve_b, include12B1, include12B2, include12B3],
            },
          ],
        },
        {
          title: "Warehouse Inspection Survey",
          sub: [
            {
              title:
                "CONDITION SURVEY TO A SILO IN CONNECTION TEMPORARY STORAGE",
              image: thirteen_a,
              images: [
                thirteen_a,
                include13A1,
                include13A2,
                include13A3,
                include13A4,
              ],
            },
            {
              title:
                "CONDITION SURVEY TO A SILO IN CONNECTION WITH TEMPORARY STORAGE OF RAW SUGAR CARGO",
              image: thirteen_b,
              images: [thirteen_b, include13B1, include13B2, include13B3],
            },
            {
              title:
                "CONDITION SURVEY TO A SILO IN MALAYSIA IN CONNECTION WITH STORAGE OF GRAIN COMMODITIES",
              image: thirteen_c,
              images: [
                thirteen_c,
                include13C1,
                include13C2,
                include13C3,
                include13C4,
                include13C5,
              ],
            },
          ],
        },
        {
          title: "Investigation Survey",
          sub: [
            {
              title: "INVESTIAGATION TO MISSING GOODS DURING DELIVERY",
              image: fourteen_a,
              images: [fourteen_a, include142],
            },
            {
              title: "INVESTIGATION TO STOLEN CARGO OF HSD ONBOARD THE SPOB",
              image: fourteen_b,
              images: [fourteen_b],
            },
          ],
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    document.title = "Abadi Cemerlang - Our Services";
  }

  handleChange(e, prop) {
    this.setState({
      [prop]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({
      showDialog: false,
    });
  };

  handleGo = (link) => {
    history.push(link);
  };

  handleOpen = (item) => {
    this.setState({
      isOpen: true,
      imagesActive: item,
    });
  };

  render() {
    return (
      <div>
        <section className="section-banner service">
          <div className="container">
            <div className="row ">
              <div className="col-md-10">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                  <div className="content text-left">
                    <h1>OUR SERVICES</h1>
                    <p>
                      Maritime incident investigations involve a complex
                      interaction of many causes. Identifying these causes
                      requires consideration, not only of the clear information
                      gleaned from reports and witness statements, photographs,
                      and wreckage, but also the impact of aspects of work
                      organization and human factors such as perception,
                      motivation, and behavior.{" "}
                    </p>

                    <p>
                      The task of a Marine Surveyor involved in an incident
                      investigation is to determine what these factors and
                      faults are and the links between them. Abadi Cemerlang
                      have extensive experience for more than 20 years in marine
                      surveys, which enables us to be fully involved in our
                      work.{" "}
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="section-services">
          {this.state.services.map((row, id) => (
            <div className="container" key={id}>
              <div className="title-wrap mt-3 text-center text-uppercase">
                <h2>{row.title}</h2>
                <div className="border-line ml-auto"> </div>
              </div>

              <div className="row ">
                {row.sub.map(
                  function (item, idx) {
                    return (
                      <div
                        className="col-md-4"
                        key={idx}
                        onClick={() =>
                          this.handleOpen(item.images ? item.images : [])
                        }
                      >
                        <div
                          className="card-image mb-2"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                        <div className="detail box">
                          <label>{item.title}</label>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )}
              </div>
            </div>
          ))}

          {/*<div className='container'>*/}
          {/*    <div className='title-wrap text-center text-uppercase'>*/}
          {/*        <h2>Towage approval survey </h2>*/}
          {/*        <div className='border-line ml-auto'> </div>*/}
          {/*    </div>*/}

          {this.state.isOpen && (
            <Lightbox
              mainSrc={this.state.imagesActive[this.state.photoIndex]}
              nextSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + 1) % this.state.imagesActive.length
                ]
              }
              prevSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + this.state.imagesActive.length - 1) %
                    this.state.imagesActive.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.imagesActive.length -
                      1) %
                    this.state.imagesActive.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.imagesActive.length,
                })
              }
            />
          )}
          <AnimationOnScroll
            initiallyVisible={false}
            delay={500}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="row ">
              {this.state.services.map((row, id) => (
                <div className="col-md-4" key={id}>
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${row.image})` }}
                  >
                    <div className="detail box">
                      <h3>{row.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AnimationOnScroll>
          {/*</div>*/}
        </section>
      </div>
    );
  }
}

export default Services;
