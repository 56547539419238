import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import Lightbox from 'react-image-lightbox';

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import section from "../../../images/contact/banner.jpeg";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

require('dotenv').config();

class Teams extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            images: [photo1,photo2],
            photoIndex: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Contact Us';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service" style={{backgroundImage: `url(${section})`}}>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>CONTACT US</h1>

                                    <p>Abadi Cemerlang pays attention to our customers and makes all efforts to improve our service to obtain maximum performance. We are aware our customers require immediate services and accuracy. If you need best-in-class execution, please get in touch with us anytime, whenever you need. Our Surveyors are at your disposal to be contacted 24 hours a day, 7 days a week.</p>
                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-map-big">
                    <div className='container-fluid'>
                        <div className='row justify-content-end align-items-center'>
                            <div className='col-md-6 col-lg-4'>
                                <small>MAIN OFFICE</small>
                                <h2>JAKARTA</h2>
                                <p>Ruko Sentra Menteng No. MN-45 <br/>
                                    Jl. Samratulangi<br/>
                                    Bintaro Sektor 7<br/>
                                    Tangerang Selatan 15224<br/>
                                    Indonesia</p>

                                <hr />
                                <ul>
                                    <li>
                                        <a href='tel:6281319801898'>
                                            <PhoneAndroidIcon /> +62 81319801898
                                        </a>
                                    </li>
                                    <li>
                                        <a href='tel:622174860595'>
                                            <PhoneIcon /> +62 21 74860595 (Hunting)
                                        </a>
                                    </li>
                                    <li>
                                        <PrintIcon /> +62 21 74861744
                                    </li>
                                    <li>
                                        <a href='mailto:abadi_cemerlang@abadisurvey.com'>
                                            <EmailIcon /> abadi_cemerlang@abadisurvey.com
                                        </a>
                                    </li>

                                </ul>
                                <h4 className='mb-0 mr-4 d-flex align-items-center'>
                                    FOLLOW US   <a href='https://www.linkedin.com/company/pt-abadi-cemerlang/' target='_blank' className='ml-2'>
                                    <LinkedInIcon />
                                </a></h4>

                            </div>
                            <div className='col-md-6 pl-md-0'>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9184359831665!2d106.72336331543245!3d-6.274454895458601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f0764c2ed52d%3A0xe88d52e3c636702a!2sPT%20Abadi%20Cemerlang!5e0!3m2!1sid!2sid!4v1588624271359!5m2!1sid!2sid"
                                    width="100%" height="800" frameBorder="0" allowFullScreen="" aria-hidden="false"
                                    tabIndex="0">

                                </iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default Teams;
