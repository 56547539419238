import React,{ Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import Logo from '../../../images/logo-small.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import queryString from 'query-string';
import PropTypes from "prop-types";


class Navbar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);

        this.state = {
            open: false,
            fix: false,
        }
    }

    componentDidMount() {

        window.addEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        let yes = false;

        if(winScroll > 100) {
            yes = true;
        }
        this.setState({
            fix: yes,
        })
    };

    handleToggleSidebar(){

        if(this.props.toggle_sidebar_state){

            this.props.setHideSidebar();

        }else{

            this.props.setShowSidebar();

        }

    }

    handleGo  = (link) => {

        history.push(link);
    };

    handleOpen  = (link) => {

        console.log(123)
        this.setState({
            open :true,
        })
    };

    handleClose  = (link) => {

        this.setState({
            open :false,
        })
    };

    render() {
        const { location } = this.props;

        const text = location.pathname.split("/");
        console.log(text);
        return(

            <header className={this.state.fix ? "header-nav fix" : "header-nav "}>
                <nav className="navbar ">
                    <div className='navbar-mobile'>
                        <Link to="/" className="navbar-brand">
                            <img src={Logo} alt='Abadi Cemerlang' className='img-fluid' />
                        </Link>
                        <button onClick={this.handleOpen}>
                            <MenuIcon />
                        </button>
                    </div>

                    <ul className={this.state.open ? "navbar-nav open": "navbar-nav "}>
                        <div className='text-right d-lg-none'>
                            <button onClick={this.handleClose}>
                                <CloseIcon />
                            </button>
                        </div>

                        <li className={(text[1] === 'our-services') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/our-services">our services </Link>
                        </li>
                        <li className={(text[1] === 'pni') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/pni">P&I</Link>
                        </li>
                        <li className={(text[1] === 'hull-machinery') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/hull-machinery">hull & machinery</Link>
                        </li>
                        <li className={(text[1] === 'marine-cargo') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/marine-cargo">marine cargo</Link>
                        </li>
                        {/*<li className="nav-item active">*/}
                        {/*    <Link className="nav-link" to="#">survey</Link>*/}
                        {/*</li>*/}
                        <li className={(text[1] === 'portfolio') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/portfolio">portfolio</Link>
                        </li>
                        <li className="nav-item d-none d-lg-block">
                            <Link to="/" className="navbar-brand">
                                <img src={Logo} alt='Abadi Cemerlang' className='img-fluid' />
                            </Link>
                        </li>
                        <li className={(text[1] === 'about') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/about">about us</Link>
                        </li>
                        <li className={(text[1] === 'contact-us') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/contact-us">contact us</Link>
                        </li>
                        <li className={(text[1] === 'our-teams') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/our-teams">our team</Link>
                        </li>
                        <li className={(text[1] === 'clients') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/clients">our client</Link>
                        </li>
                    </ul>

                </nav>
            </header>

        )

    }

}

const mapStateToProps = (state) => {

    return {
        open_state: state.open_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));