import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { history } from "../../../shared/configure-store";
import Lightbox from "react-image-lightbox";

// 1A
import MC_1A1 from "../../../images/marine-cargo/1/A/1.jpg";
import MC_1A2 from "../../../images/marine-cargo/1/A/2.jpg";
import MC_1A3 from "../../../images/marine-cargo/1/A/3.jpg";
import MC_1A4 from "../../../images/marine-cargo/1/A/4.jpg";
import MC_1A5 from "../../../images/marine-cargo/1/A/5.jpg";
import MC_1A6 from "../../../images/marine-cargo/1/A/6.jpg";
import MC_1A7 from "../../../images/marine-cargo/1/A/7.jpg";
import MC_1A8 from "../../../images/marine-cargo/1/A/8.jpg";
import MC_1A9 from "../../../images/marine-cargo/1/A/9.jpg";
import MC_1A10 from "../../../images/marine-cargo/1/A/10.jpg";
import MC_1A11 from "../../../images/marine-cargo/1/A/11.jpg";
import MC_1A12 from "../../../images/marine-cargo/1/A/12.jpg";

// 2A
import MC_2A1 from "../../../images/marine-cargo/2/A/1.jpg";
import MC_2A2 from "../../../images/marine-cargo/2/A/2.jpg";
import MC_2A3 from "../../../images/marine-cargo/2/A/3.jpg";
import MC_2A4 from "../../../images/marine-cargo/2/A/4.jpg";
import MC_2A5 from "../../../images/marine-cargo/2/A/5.jpg";
import MC_2A6 from "../../../images/marine-cargo/2/A/6.jpg";
import MC_2A7 from "../../../images/marine-cargo/2/A/7.jpg";
import MC_2A8 from "../../../images/marine-cargo/2/A/8.jpg";
import MC_2A9 from "../../../images/marine-cargo/2/A/9.jpg";
import MC_2A10 from "../../../images/marine-cargo/2/A/10.jpg";
import MC_2A11 from "../../../images/marine-cargo/2/A/11.jpg";

// 2B
import MC_2B1 from "../../../images/marine-cargo/2/B/1.jpeg";
import MC_2B2 from "../../../images/marine-cargo/2/B/2.jpeg";
import MC_2B3 from "../../../images/marine-cargo/2/B/3.jpeg";
import MC_2B4 from "../../../images/marine-cargo/2/B/4.jpeg";
import MC_2B5 from "../../../images/marine-cargo/2/B/5.jpeg";
import MC_2B6 from "../../../images/marine-cargo/2/B/6.jpeg";
import MC_2B7 from "../../../images/marine-cargo/2/B/7.jpeg";
import MC_2B8 from "../../../images/marine-cargo/2/B/8.jpeg";
import MC_2B9 from "../../../images/marine-cargo/2/B/9.jpeg";
import MC_2B10 from "../../../images/marine-cargo/2/B/10.jpeg";
import MC_2B11 from "../../../images/marine-cargo/2/B/11.jpeg";
import MC_2B12 from "../../../images/marine-cargo/2/B/12.jpeg";
import MC_2B13 from "../../../images/marine-cargo/2/B/13.jpeg";
import MC_2B14 from "../../../images/marine-cargo/2/B/14.jpeg";
import MC_2B15 from "../../../images/marine-cargo/2/B/15.jpeg";

// 3A
import MC_3A1 from "../../../images/marine-cargo/3/A/1.jpeg";
import MC_3A2 from "../../../images/marine-cargo/3/A/2.jpeg";
import MC_3A3 from "../../../images/marine-cargo/3/A/3.jpeg";
import MC_3A4 from "../../../images/marine-cargo/3/A/4.jpeg";
import MC_3A5 from "../../../images/marine-cargo/3/A/5.jpeg";
import MC_3A6 from "../../../images/marine-cargo/3/A/6.jpeg";
import MC_3A7 from "../../../images/marine-cargo/3/A/7.jpeg";

// 3B
import MC_3B1 from "../../../images/marine-cargo/3/B/1.jpg";
import MC_3B2 from "../../../images/marine-cargo/3/B/2.jpg";
import MC_3B3 from "../../../images/marine-cargo/3/B/3.jpg";
import MC_3B4 from "../../../images/marine-cargo/3/B/4.jpg";
import MC_3B5 from "../../../images/marine-cargo/3/B/5.jpg";

// 3C
import MC_3C1 from "../../../images/marine-cargo/3/C/1.jpeg";
import MC_3C2 from "../../../images/marine-cargo/3/C/2.jpeg";
import MC_3C3 from "../../../images/marine-cargo/3/C/3.jpeg";
import MC_3C4 from "../../../images/marine-cargo/3/C/4.jpeg";
import MC_3C5 from "../../../images/marine-cargo/3/C/5.jpeg";

// 3D
import MC_3D1 from "../../../images/marine-cargo/3/D/1.jpg";
import MC_3D2 from "../../../images/marine-cargo/3/D/2.jpg";
import MC_3D3 from "../../../images/marine-cargo/3/D/3.jpg";
import MC_3D4 from "../../../images/marine-cargo/3/D/4.jpg";
import MC_3D5 from "../../../images/marine-cargo/3/D/5.jpg";
import MC_3D6 from "../../../images/marine-cargo/3/D/6.jpg";
import MC_3D7 from "../../../images/marine-cargo/3/D/7.jpg";

// 3E
import MC_3E1 from "../../../images/marine-cargo/3/E/1.jpeg";
import MC_3E2 from "../../../images/marine-cargo/3/E/2.jpeg";
import MC_3E3 from "../../../images/marine-cargo/3/E/3.jpeg";
import MC_3E4 from "../../../images/marine-cargo/3/E/4.jpeg";
import MC_3E5 from "../../../images/marine-cargo/3/E/5.jpeg";

// 3F
import MC_3F1 from "../../../images/marine-cargo/3/F/1.jpg";
import MC_3F2 from "../../../images/marine-cargo/3/F/2.jpg";
import MC_3F3 from "../../../images/marine-cargo/3/F/3.jpg";
import MC_3F4 from "../../../images/marine-cargo/3/F/4.jpg";
import MC_3F5 from "../../../images/marine-cargo/3/F/5.jpg";
import MC_3F6 from "../../../images/marine-cargo/3/F/6.jpg";
import MC_3F7 from "../../../images/marine-cargo/3/F/7.jpg";
import MC_3F8 from "../../../images/marine-cargo/3/F/8.jpg";
import MC_3F9 from "../../../images/marine-cargo/3/F/9.jpg";

// 3G
import MC_3G1 from "../../../images/marine-cargo/3/G/1.jpg";
import MC_3G2 from "../../../images/marine-cargo/3/G/2.jpg";
import MC_3G3 from "../../../images/marine-cargo/3/G/3.jpg";
import MC_3G4 from "../../../images/marine-cargo/3/G/4.jpg";

// 3H
import MC_3H1 from "../../../images/marine-cargo/3/H/1.jpeg";
import MC_3H2 from "../../../images/marine-cargo/3/H/2.jpeg";
import MC_3H3 from "../../../images/marine-cargo/3/H/3.jpeg";
import MC_3H4 from "../../../images/marine-cargo/3/H/4.jpeg";
import MC_3H5 from "../../../images/marine-cargo/3/H/5.jpeg";
import MC_3H6 from "../../../images/marine-cargo/3/H/6.jpeg";
import MC_3H7 from "../../../images/marine-cargo/3/H/7.jpeg";
import MC_3H8 from "../../../images/marine-cargo/3/H/8.jpeg";
import MC_3H9 from "../../../images/marine-cargo/3/H/9.jpeg";
import MC_3H10 from "../../../images/marine-cargo/3/H/10.jpeg";
import MC_3H11 from "../../../images/marine-cargo/3/H/11.jpeg";
import MC_3H12 from "../../../images/marine-cargo/3/H/12.jpeg";

// 3I
import MC_3I1 from "../../../images/marine-cargo/3/I/1.jpg";
import MC_3I2 from "../../../images/marine-cargo/3/I/2.jpg";
import MC_3I3 from "../../../images/marine-cargo/3/I/3.jpg";
import MC_3I4 from "../../../images/marine-cargo/3/I/4.jpg";

// 3J
import MC_3J1 from "../../../images/marine-cargo/3/J/1.jpeg";
import MC_3J2 from "../../../images/marine-cargo/3/J/2.jpeg";
import MC_3J3 from "../../../images/marine-cargo/3/J/3.jpeg";
import MC_3J4 from "../../../images/marine-cargo/3/J/4.jpeg";
import MC_3J5 from "../../../images/marine-cargo/3/J/5.jpeg";

// 3K
import MC_3K1 from "../../../images/marine-cargo/3/K/1.jpeg";
import MC_3K2 from "../../../images/marine-cargo/3/K/2.jpeg";
import MC_3K3 from "../../../images/marine-cargo/3/K/3.jpeg";
import MC_3K4 from "../../../images/marine-cargo/3/K/4.jpeg";
import MC_3K5 from "../../../images/marine-cargo/3/K/5.jpeg";
import MC_3K6 from "../../../images/marine-cargo/3/K/6.jpeg";
import MC_3K7 from "../../../images/marine-cargo/3/K/7.jpeg";

// 3L
import MC_3L1 from "../../../images/marine-cargo/3/L/1.jpg";
import MC_3L2 from "../../../images/marine-cargo/3/L/2.jpg";
import MC_3L3 from "../../../images/marine-cargo/3/L/3.jpg";
import MC_3L4 from "../../../images/marine-cargo/3/L/4.jpg";
import MC_3L5 from "../../../images/marine-cargo/3/L/5.jpg";
import MC_3L6 from "../../../images/marine-cargo/3/L/6.jpg";
import MC_3L7 from "../../../images/marine-cargo/3/L/7.jpg";
import MC_3L8 from "../../../images/marine-cargo/3/L/8.jpg";


require("dotenv").config();

class MarineCargo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      photoIndex: 0,
      services: [
        {
          title: "PRESHIPMENT & CONDITION SURVEY ",
          sub: [
            {
              title: "LOADING SUPERVISION FROM SHORE TANK FOR CARGO OF GLYCERINE", // ASP SUNRISE
              image: MC_1A1,
              images: [MC_1A1, MC_1A2, MC_1A3, MC_1A4, MC_1A5, MC_1A6, MC_1A7, MC_1A8, MC_1A9, MC_1A10, MC_1A11, MC_1A12],
            },
          ],
        },
        {
          title: "DRAFT SURVEY – TALLY – CONDITION SURVEY",
          sub: [
            {
              title: "CARGO OF SOYBEAN IN CARGO HOLD FOUND WET", // MV CRYSTAL
              image: MC_2A1,
              images: [MC_2A1, MC_2A2, MC_2A3, MC_2A4, MC_2A5, MC_2A6, MC_2A7, MC_2A8, MC_2A9, MC_2A10, MC_2A11],
            },
            {
              title: "TALLY SURVEY FOR BAGS OF RICE DURING DISCHARGE", // SCORPIO GLORY
              image: MC_2B1,
              images: [MC_2B1, MC_2B2, MC_2B3, MC_2B4, MC_2B5, MC_2B6, MC_2B7, MC_2B8, MC_2B9, MC_2B10, MC_2B11, MC_2B12, MC_2B13, MC_2B14, MC_2B15],
            },
          ],
        },
        {
          title: "DAMAGE CARGO SURVEY, SHORTAGE AND SALVAGE TENDER",
          sub: [
            {
              title: "CARGO OF REFINED GLYCERINE FOUND CONTAMINATED AFTER DISCHARGE", // MT SIRIUS - GLYCERINE
              image: MC_3A1,
              images: [MC_3A1, MC_3A2, MC_3A3, MC_3A4, MC_3A5, MC_3A6, MC_3A7],
            },
            {
              title: "CARGO OF FLUORITE BALLS IN TON BAGS FOUND POLLUTED BY FUEL OIL", // AH EVER
              image: MC_3B1,
              images: [MC_3B1, MC_3B2, MC_3B3, MC_3B4, MC_3B5],
            },
            {
              title: "CARGO OF ALUMINIUM FLUORIDE IN BAGS FOUND BURNT AND TORN", // HUA XIN
              image: MC_3C1,
              images: [MC_3C1, MC_3C2, MC_3C3, MC_3C4, MC_3C5],
            },
            {
              title: "CARGO OF TON BAGS ALUMINA WAS TORN DURING DISCHARGE", // KUMANO
              image: MC_3D1,
              images: [MC_3D1, MC_3D2, MC_3D3, MC_3D4, MC_3D5, MC_3D6, MC_3D7],
            },
            {
              title: "CARGO OF PALM KERNEL IN BAG SUBMERGED", // SIBOLANGIT
              image: MC_3E1,
              images: [MC_3E1, MC_3E2, MC_3E3, MC_3E4, MC_3E5],
            },
            {
              title: "CARGO FOUND WET DURING UNSTUFFED", // INTAN DAYA 88
              image: MC_3F1,
              images: [MC_3F1, MC_3F2, MC_3F3, MC_3F4, MC_3F5, MC_3F6, MC_3F7, MC_3F8, MC_3F9],
            },
            {
              title: "SHORTAGE IN THE CPO CARGO HAS BEEN DISCOVERED", // PMT IV 915
              image: MC_3G1,
              images: [MC_3G1, MC_3G2, MC_3G3, MC_3G4],
            },
            {
              title: "CARGO OF HOT ROLLED STEEL SHEET IN COILS FOUND DAMAGE DUE BAD WEATHER", // TRIUMPH OCEAN
              image: MC_3H1,
              images: [MC_3H1, MC_3H2, MC_3H3, MC_3H4, MC_3H5, MC_3H6, MC_3H7, MC_3H8, MC_3H9, MC_3H10, MC_3H11, MC_3H12],
            },
            {
              title: "TENDER SALVAGE FOR DAMAGED CARGO OF SOYBEAN", // 4517
              image: MC_3I1,
              images: [MC_3I1, MC_3I2, MC_3I3, MC_3I4],
            },
            {
              title: "CARGO OF STEEL COIL FOUND RUSTED AND DIRT STAINED", // APOLLO STELLA
              image: MC_3J1,
              images: [MC_3J1, MC_3J2, MC_3J3, MC_3J4, MC_3J5],
            },
            {
              title: "TENDER SALVAGE FOR DAMAGED CARGO OF GROUND NUTS", // KOTA MACHAN
              image: MC_3K1,
              images: [MC_3K1, MC_3K2, MC_3K3, MC_3K4, MC_3K5, MC_3K6, MC_3K7],
            },
            {
              title: "CARGO OF SOYBEANS FOUND WET DURING UNLOADING", // 4536
              image: MC_3L1,
              images: [MC_3L1, MC_3L2, MC_3L3, MC_3L4, MC_3L5, MC_3L6, MC_3L7, MC_3L7, MC_3L8],
            },
          ],
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.title = "Abadi Cemerlang - Marine Cargo";
  }

  handleChange(e, prop) {
    this.setState({
      [prop]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({
      showDialog: false,
    });
  };

  handleGo = (link) => {
    history.push(link);
  };

  handleOpen = (item) => {
    this.setState({
      isOpen: true,
      imagesActive: item,
    });
  };

  render() {
    return (
      <div>
        <section className="section-banner service marine-cargo">
          <div className="container">
            <div className="row ">
              <div className="col-md-10">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                  <div className="content text-left">
                    <h1>Marine Cargo</h1>
                    <p>
                    Our Marine Cargo services provide comprehensive protection against the loss or damage of goods during transit, 
                    whether by sea, air, or land. With over 20 years of experience, Abadi Cemerlang ensures the safe handling of cargo claims, 
                    from initial investigation to final settlement. We assist clients in mitigating risks, ensuring claims are processed swiftly and efficiently, 
                    and providing expert advice to minimize future incidents. Our team works closely with underwriters and surveyors to deliver tailored solutions, 
                    ensuring your cargo reaches its destination safely and securely.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="section-services">
          {this.state.services.map((row, id) => (
            <div className="container" key={id}>
              <div className="title-wrap mt-3 text-center text-uppercase">
                <h2>{row.title}</h2>
                <div className="border-line ml-auto"> </div>
              </div>

              <div className="row ">
                {row.sub.map(
                  function (item, idx) {
                    return (
                      <div
                        className="col-md-4"
                        key={idx}
                        onClick={() =>
                          this.handleOpen(item.images ? item.images : [])
                        }
                      >
                        <div
                          className="card-image mb-2"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                        <div className="detail box">
                          <label>{item.title}</label>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )}
              </div>
            </div>
          ))}
          {this.state.isOpen && (
            <Lightbox
              mainSrc={this.state.imagesActive[this.state.photoIndex]}
              nextSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + 1) % this.state.imagesActive.length
                ]
              }
              prevSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + this.state.imagesActive.length - 1) %
                    this.state.imagesActive.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.imagesActive.length -
                      1) %
                    this.state.imagesActive.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.imagesActive.length,
                })
              }
            />
          )}
          <AnimationOnScroll
            initiallyVisible={false}
            delay={500}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="row ">
              {this.state.services.map((row, id) => (
                <div className="col-md-4" key={id}>
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${row.image})` }}
                  >
                    <div className="detail box">
                      <h3>{row.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AnimationOnScroll>
        </section>
      </div>
    );
  }
}

export default MarineCargo;
