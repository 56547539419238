export const SET_INITIAL_PROFILE_STATE = 'SET_INITIAL_PROFILE_STATE';

export const SET_PROFILE_STATE = 'SET_PROFILE_STATE';



export const SET_INITIAL_TOGGLE_SIDEBAR_STATE = 'SET_INITIAL_TOGGLE_SIDEBAR_STATE';

export const SET_SHOW_SIDEBAR = 'SET_SHOW_SIDEBAR';

export const SET_HIDE_SIDEBAR = 'SET_HIDE_SIDEBAR';


export const SET_INITIAL_ROLE_STATE = 'SET_INITIAL_ROLE_STATE';

export const SET_ROLE_STATE = 'SET_ROLE_STATE';


export const SET_INITIAL_PERMISSION_STATE = 'SET_INITIAL_PERMISSION_STATE';

export const SET_PERMISSION_STATE = 'SET_PERMISSION_STATE';

