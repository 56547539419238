import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import Lightbox from 'react-image-lightbox';

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import section from "../../../images/clients/banner.jpg";
import image1 from "../../../images/clients/logos.png";

require('dotenv').config();

class Client extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            images: [photo1,photo2],
            photoIndex: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Our Clients';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service" style={{backgroundImage: `url(${section})`}}>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>OUR CLIENTS</h1>

                                    <p>With many years of experience, we have partner with international brokers, underwriters, and P&I clubs also with the local Re-Insurance, brokers, underwriters and adjuster.  </p>
                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-content">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-10 text-center img-logo'>
                                <h2 className='mb-4'>SOME OF THE COMPANIES WE PROUDLY WORK WITH</h2>
                                <img src={image1} className='img-fluid' />


                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-content clients">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className=''>UNDERWRITER</h2>
                            </div>

                            <div className='col-md-6'>
                                <h3>LOCAL</h3>
                                <ul>
                                    <li>ASURANSI MAG</li>
                                    <li>ASURANSI ASTRA</li>
                                        <li>ASURNASI JASINDO</li>
                                            <li>ASKRINDO</li>
                                                <li>ASURANSI ACA</li>
                                    <li>TUGU PRATAMA INDONESIA</li>
                                    <li>DAYIN MITRA</li>
                                    <li>TAKAFUL UMUM</li>
                                    <li>MAXIMUS</li>
                                    <li>JASA RAHARJA PUTERA</li>
                                    <li>TRIPAKARTA</li>
                                    <li>ASURNASI UMUM MEGA</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <h3>INTERNATIONAL</h3>
                                <ul>
                                    <li>GREAT AMERICAN INSURANCE</li>
                                    <li>ALLIANZ</li>
                                    <li>AXA XL</li>
                                    <li>CHUBB</li>
                                    <li>CHINA TAIPING</li>
                                    <li>QBE</li>
                                </ul>
                            </div>
                            <div className='col-12'>
                                <h2>P&I CLUBS</h2>
                            </div>
                            <div className='col-md-6'>
                                <h3>MUTUAL</h3>
                                <ul>
                                    <li>BRITANNIA P&I</li>
                                    <li>CHINA P&I</li>
                                    <li> GARD</li>
                                    <li>JAPAN P&I</li>
                                    <li>NORTH OF ENGLAND</li>
                                    <li>SKULD</li>
                                    <li>STEAMSHIP MUTUAL</li>
                                    <li>THE LONDON P&I CLUB</li>
                                    <li>THE SHIPOWNERS’ PROTECTION</li>
                                    <li>THE STANDARD P&I CLUB</li>
                                    <li>THE SWEDISH CLUB</li>
                                    <li>UK P&I CLUB</li>
                                    <li>WEST OF ENGLAND</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <h3>FIXED PREMIUM</h3>
                                <ul>
                                    <li> MS AMLIN</li>
                                    <li>EAGLE OCEAN MARINE</li>
                                    <li>EF MARINE</li>
                                    <li>THOMAS MILLER</li>
                                </ul>
                            </div>
                            <div className='col-12'>
                                <h2>BROKERS</h2>
                            </div>
                            <div className='col-md-6'>
                                <h3>INTERNATIONAL</h3>
                                <ul>
                                    <li>LCH SINGAPORE</li>
                                    <li>MARSH</li>
                                    <li>CAMBIASO RISSO</li>
                                    <li>AON</li>
                                    <li>WILLIS TOWER WATSON</li>
                                <li>COLLYERS LONDON</li>
                                    <li>EDGE LONDON</li>

                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <h3>LOCAL</h3>
                                <ul>
                                    <li>KBRU</li>
                                    <li>POLYNESIA BHAKTI</li>
                                    <li>SPICA</li>
                                    <li>DAIDAN UTAMA</li>
                                    <li>PANDI PROTEKSI MARINE INDOSNESIA</li>
                                    <li>PEMENANG KONSULTANSI ASURANSI</li>

                                </ul>
                            </div>
                            <div className='col-12'>
                                <h2>ADJUSTER</h2>
                            </div>
                            <div className='col-md-6'>
                                <h3>INTERNATIONAL</h3>
                                <ul>
                                    <li> POSEIDON ADJUSTER</li>
                                    <li> CHARLES TAYLOR</li>
                                    <li> MCO SINGAPORE</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <h3>LOCAL</h3>
                                <ul>
                                    <li>RADITA HUTAMA INDONESIA</li>
                                    <li>GLOBAL INTERNUSA ADJUSTING</li>
                                    <li>PANDU HALIM PERKASA</li>
                                    <li>MCO INDONESIA</li>
                                </ul>
                            </div>
                            <div className='col-12'>
                                <h2>SHIPOWNERS </h2>
                            </div>
                            <div className='col-md-6'>
                                <ul>
                                    <li>YACHT SOURCING</li>
                                    <li>THE MAJ OCEANIC</li>
                                    <li>PERTAMINA</li>
                                    <li>INDOBARUNA BULK TRANSPORT</li>
                                    <li>PELAYARAN NASIONAL INDONESIA (PELNI)</li>
                                    <li>ASDP INDONESIA FERRY (PERSERO)</li>
                                    <li>SALAM PASIFIC SEJAHTERA LINE (SPIL)</li>
                                    <li>SAMUDERA INDONESIA</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <ul>
                                    <li> MERATUS LINE</li>
                                    <li>WARUNA NUSA SENTANA</li>
                                    <li> KARTIKA SAMUDRA ADIJAYA</li>
                                    <li> PATRIA MARITIME LINES</li>
                                    <li> MARITIM BARITO PERKASA</li>
                                <li>BARUNA DIRGA DHARMA</li>
                                    <li> SOECHI LINES</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        )
    }
}

export default Client;
