import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { history } from "../../../shared/configure-store";
import Lightbox from "react-image-lightbox";

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";

import one_a from "../../../images/hnm/1/A/1.JPG";
import one_b from "../../../images/hnm/1/B/1.JPG";
import one_c from "../../../images/hnm/1/C/1.jpg";
import one_d from "../../../images/hnm/1/D/1.JPG";
import one_e from "../../../images/hnm/1/E/1.jpg";
import one_f from "../../../images/hnm/1/F/1.JPG";
import two_a from "../../../images/hnm/2/A/1.JPG";
import two_b from "../../../images/hnm/2/B/1.JPG";
import two_c from "../../../images/hnm/2/C/1.JPG";
import two_d from "../../../images/hnm/2/D/1.jpeg";
import three_a from "../../../images/hnm/3/A/1.JPG";
import three_b from "../../../images/hnm/3/B/1.jpg";
import three_c from "../../../images/hnm/3/C/1.JPG";
import three_d from "../../../images/hnm/3/D/1.JPG";
import three_e from "../../../images/hnm/3/E/1.JPG";
import three_f from "../../../images/hnm/3/F/1.JPG";
import three_g from "../../../images/hnm/3/G/1.JPG";
import three_h from "../../../images/hnm/3/H/1.JPG";
import fourth_a from "../../../images/hnm/4/1.JPG";
import five_a from "../../../images/hnm/5/A/1.JPG";
import five_b from "../../../images/hnm/5/B/1.JPG";
import five_c from "../../../images/hnm/5/C/1.JPG";
import six_a from "../../../images/hnm/6/A/1.JPG";
import six_b from "../../../images/hnm/6/B/1.jpg";
import six_c from "../../../images/hnm/6/C/1.jpeg";
import six_d from "../../../images/hnm/6/D/1.jpg";
import six_e from "../../../images/hnm/6/E/1.JPG";
import six_f from "../../../images/hnm/6/F/1.jpeg";
import six_g from "../../../images/hnm/6/G/1.jpeg";
import six_h from "../../../images/hnm/6/H/1.JPG";
import six_i from "../../../images/hnm/6/I/1.JPG";
import six_j from "../../../images/hnm/6/J/1.JPG";
import six_k from "../../../images/hnm/6/K/1.JPG";
import six_l from "../../../images/hnm/6/L/1.JPG";

//
import hnm1a2 from "../../../images/hnm/1/A/2.JPG";
import hnm1a3 from "../../../images/hnm/1/A/3.JPG";

import hnm1b2 from "../../../images/hnm/1/B/2.JPG";

import hnm1c2 from "../../../images/hnm/1/C/2.jpg";
import hnm1c3 from "../../../images/hnm/1/C/3.jpg";
import hnm1c4 from "../../../images/hnm/1/C/4.jpg";
import hnm1c5 from "../../../images/hnm/1/C/5.jpg";

import hnm1d2 from "../../../images/hnm/1/D/2.JPG";
import hnm1d3 from "../../../images/hnm/1/D/3.JPG";
import hnm1d4 from "../../../images/hnm/1/D/4.JPG";
import hnm1d5 from "../../../images/hnm/1/D/5.JPG";
import hnm1d6 from "../../../images/hnm/1/D/6.JPG";

import hnm1e2 from "../../../images/hnm/1/E/2.jpg";
import hnm1e3 from "../../../images/hnm/1/E/3.jpg";
import hnm1e4 from "../../../images/hnm/1/E/4.jpg";

import hnm1f2 from "../../../images/hnm/1/F/2.JPG";
import hnm1f3 from "../../../images/hnm/1/F/3.JPG";
import hnm1f4 from "../../../images/hnm/1/F/4.JPG";
import hnm1f5 from "../../../images/hnm/1/F/5.JPG";

import hnm2a2 from "../../../images/hnm/2/A/2.JPG";
import hnm2a3 from "../../../images/hnm/2/A/3.JPG";
import hnm2a4 from "../../../images/hnm/2/A/4.JPG";

import hnm2b2 from "../../../images/hnm/2/B/2.JPG";
import hnm2b3 from "../../../images/hnm/2/B/3.JPG";
import hnm2b4 from "../../../images/hnm/2/B/4.JPG";
import hnm2b5 from "../../../images/hnm/2/B/5.JPG";

import hnm2c2 from "../../../images/hnm/2/C/2.JPG";
import hnm2c3 from "../../../images/hnm/2/C/3.JPG";
import hnm2c4 from "../../../images/hnm/2/C/4.JPG";
import hnm2c5 from "../../../images/hnm/2/C/5.JPG";

import hnm2d2 from "../../../images/hnm/2/D/2.jpeg";
import hnm2d3 from "../../../images/hnm/2/D/3.jpeg";
import hnm2d4 from "../../../images/hnm/2/D/4.jpeg";

import hnm3a2 from "../../../images/hnm/3/A/2.JPG";
import hnm3a3 from "../../../images/hnm/3/A/3.JPG";
import hnm3a4 from "../../../images/hnm/3/A/4.JPG";
import hnm3a5 from "../../../images/hnm/3/A/5.JPG";
import hnm3a6 from "../../../images/hnm/3/A/6.JPG";
import hnm3a7 from "../../../images/hnm/3/A/7.JPG";
import hnm3a8 from "../../../images/hnm/3/A/8.JPG";

import hnm3b2 from "../../../images/hnm/3/B/2.JPG";
import hnm3b3 from "../../../images/hnm/3/B/3.JPG";
import hnm3b4 from "../../../images/hnm/3/B/4.JPG";
import hnm3b5 from "../../../images/hnm/3/B/5.JPG";
import hnm3b6 from "../../../images/hnm/3/B/6.JPG";
import hnm3b7 from "../../../images/hnm/3/B/7.JPG";
import hnm3b8 from "../../../images/hnm/3/B/8.JPG";
import hnm3b9 from "../../../images/hnm/3/B/9.JPG";
import hnm3b10 from "../../../images/hnm/3/B/10.JPG";
import hnm3b11 from "../../../images/hnm/3/B/11.JPG";
import hnm3b12 from "../../../images/hnm/3/B/12.JPG";
import hnm3b13 from "../../../images/hnm/3/B/13.JPG";
import hnm3b14 from "../../../images/hnm/3/B/14.JPG";
import hnm3b15 from "../../../images/hnm/3/B/15.jpg";
import hnm3b16 from "../../../images/hnm/3/B/16.JPG";
import hnm3b17 from "../../../images/hnm/3/B/17.JPG";
import hnm3b18 from "../../../images/hnm/3/B/18.JPG";
import hnm3b19 from "../../../images/hnm/3/B/19.JPG";

import hnm3c2 from "../../../images/hnm/3/C/2.JPG";
import hnm3c3 from "../../../images/hnm/3/C/3.JPG";
import hnm3c4 from "../../../images/hnm/3/C/4.JPG";
import hnm3c5 from "../../../images/hnm/3/C/5.JPG";
import hnm3c6 from "../../../images/hnm/3/C/6.JPG";
import hnm3c7 from "../../../images/hnm/3/C/7.JPG";
import hnm3c8 from "../../../images/hnm/3/C/8.JPG";
import hnm3c9 from "../../../images/hnm/3/C/9.JPG";
import hnm3c10 from "../../../images/hnm/3/C/10.JPG";
import hnm3c11 from "../../../images/hnm/3/C/11.JPG";
import hnm3c12 from "../../../images/hnm/3/C/12.JPG";
import hnm3c13 from "../../../images/hnm/3/C/13.JPG";

import hnm3d2 from "../../../images/hnm/3/D/2.JPG";
import hnm3d3 from "../../../images/hnm/3/D/3.JPG";
import hnm3d4 from "../../../images/hnm/3/D/4.JPG";
import hnm3d5 from "../../../images/hnm/3/D/5.JPG";
import hnm3d6 from "../../../images/hnm/3/D/6.JPG";
import hnm3d7 from "../../../images/hnm/3/D/7.JPG";

import hnm3e2 from "../../../images/hnm/3/E/2.JPG";
import hnm3e3 from "../../../images/hnm/3/E/3.JPG";
import hnm3e4 from "../../../images/hnm/3/E/4.JPG";
import hnm3e5 from "../../../images/hnm/3/E/5.JPG";
import hnm3e6 from "../../../images/hnm/3/E/6.JPG";
import hnm3e7 from "../../../images/hnm/3/E/7.JPG";
import hnm3e8 from "../../../images/hnm/3/E/8.JPG";
import hnm3e9 from "../../../images/hnm/3/E/9.JPG";
import hnm3e10 from "../../../images/hnm/3/E/10.JPG";
import hnm3e11 from "../../../images/hnm/3/E/11.JPG";
import hnm3e12 from "../../../images/hnm/3/E/12.JPG";
import hnm3e13 from "../../../images/hnm/3/E/13.JPG";
import hnm3e14 from "../../../images/hnm/3/E/14.JPG";
import hnm3e15 from "../../../images/hnm/3/E/15.JPG";
import hnm3e16 from "../../../images/hnm/3/E/16.JPG";
import hnm3e17 from "../../../images/hnm/3/E/17.JPG";
import hnm3e18 from "../../../images/hnm/3/E/18.JPG";
import hnm3e19 from "../../../images/hnm/3/E/19.JPG";
import hnm3e20 from "../../../images/hnm/3/E/20.JPG";
import hnm3e21 from "../../../images/hnm/3/E/21.JPG";
import hnm3e22 from "../../../images/hnm/3/E/22.JPG";
import hnm3e23 from "../../../images/hnm/3/E/23.JPG";
import hnm3e24 from "../../../images/hnm/3/E/24.JPG";
import hnm3e25 from "../../../images/hnm/3/E/25.JPG";
import hnm3e26 from "../../../images/hnm/3/E/26.JPG";
import hnm3e27 from "../../../images/hnm/3/E/27.JPG";
import hnm3e28 from "../../../images/hnm/3/E/28.JPG";
import hnm3e29 from "../../../images/hnm/3/E/29.JPG";

import hnm3f2 from "../../../images/hnm/3/F/2.JPG";
import hnm3f3 from "../../../images/hnm/3/F/3.JPG";
import hnm3f4 from "../../../images/hnm/3/F/4.JPG";
import hnm3f5 from "../../../images/hnm/3/F/5.JPG";
import hnm3f6 from "../../../images/hnm/3/F/6.JPG";
import hnm3f7 from "../../../images/hnm/3/F/7.JPG";
import hnm3f8 from "../../../images/hnm/3/F/8.JPG";
import hnm3f9 from "../../../images/hnm/3/F/9.JPG";
import hnm3f10 from "../../../images/hnm/3/F/10.JPG";
import hnm3f11 from "../../../images/hnm/3/F/11.JPG";
import hnm3f12 from "../../../images/hnm/3/F/12.JPG";
import hnm3f13 from "../../../images/hnm/3/F/13.JPG";
import hnm3f14 from "../../../images/hnm/3/F/14.JPG";
import hnm3f15 from "../../../images/hnm/3/F/15.JPG";
import hnm3f16 from "../../../images/hnm/3/F/16.JPG";
import hnm3f17 from "../../../images/hnm/3/F/17.JPG";
import hnm3f18 from "../../../images/hnm/3/F/18.JPG";
import hnm3f19 from "../../../images/hnm/3/F/19.JPG";
import hnm3f20 from "../../../images/hnm/3/F/20.JPG";
import hnm3f21 from "../../../images/hnm/3/F/21.JPG";
import hnm3f22 from "../../../images/hnm/3/F/22.JPG";
import hnm3f23 from "../../../images/hnm/3/F/23.JPG";
import hnm3f24 from "../../../images/hnm/3/F/24.JPG";

import hnm3g2 from "../../../images/hnm/3/G/2.JPG";
import hnm3g3 from "../../../images/hnm/3/G/3.JPG";
import hnm3g4 from "../../../images/hnm/3/G/4.JPG";
import hnm3g5 from "../../../images/hnm/3/G/5.JPG";
import hnm3g6 from "../../../images/hnm/3/G/6.JPG";
import hnm3g7 from "../../../images/hnm/3/G/7.JPG";
import hnm3g8 from "../../../images/hnm/3/G/8.JPG";
import hnm3g9 from "../../../images/hnm/3/G/9.JPG";
import hnm3g10 from "../../../images/hnm/3/G/10.JPG";
import hnm3g11 from "../../../images/hnm/3/G/11.JPG";

import hnm3h2 from "../../../images/hnm/3/H/2.JPG";
import hnm3h3 from "../../../images/hnm/3/H/3.JPG";
import hnm3h4 from "../../../images/hnm/3/H/4.JPG";
import hnm3h5 from "../../../images/hnm/3/H/5.JPG";
import hnm3h6 from "../../../images/hnm/3/H/6.JPG";
import hnm3h7 from "../../../images/hnm/3/H/7.JPG";

import hnm3h1 from "../../../images/hnm/4/1.JPG";

import hnm5a2 from "../../../images/hnm/5/A/2.JPG";
import hnm5a3 from "../../../images/hnm/5/A/3.JPG";
import hnm5a4 from "../../../images/hnm/5/A/4.JPG";
import hnm5a5 from "../../../images/hnm/5/A/5.JPG";
import hnm5a6 from "../../../images/hnm/5/A/6.JPG";

import hnm5b2 from "../../../images/hnm/5/B/2.JPG";
import hnm5b3 from "../../../images/hnm/5/B/3.JPG";
import hnm5b4 from "../../../images/hnm/5/B/4.JPG";
import hnm5b5 from "../../../images/hnm/5/B/5.JPG";

import hnm5c2 from "../../../images/hnm/5/C/2.JPG";
import hnm5c3 from "../../../images/hnm/5/C/3.JPG";
import hnm5c4 from "../../../images/hnm/5/C/4.JPG";

import hnm6a2 from "../../../images/hnm/6/A/2.JPG";
import hnm6a3 from "../../../images/hnm/6/A/3.JPG";
import hnm6a4 from "../../../images/hnm/6/A/4.JPG";
import hnm6a5 from "../../../images/hnm/6/A/5.JPG";
import hnm6a6 from "../../../images/hnm/6/A/6.JPG";
import hnm6a7 from "../../../images/hnm/6/A/7.JPG";
import hnm6a8 from "../../../images/hnm/6/A/8.JPG";
import hnm6a9 from "../../../images/hnm/6/A/9.JPG";
import hnm6a10 from "../../../images/hnm/6/A/10.JPG";
import hnm6a11 from "../../../images/hnm/6/A/11.JPG";
import hnm6a12 from "../../../images/hnm/6/A/12.JPG";
import hnm6a13 from "../../../images/hnm/6/A/13.JPG";

import hnm6b2 from "../../../images/hnm/6/B/2.jpg";
import hnm6b3 from "../../../images/hnm/6/B/3.jpg";
import hnm6b4 from "../../../images/hnm/6/B/4.jpg";
import hnm6b5 from "../../../images/hnm/6/B/5.jpg";
import hnm6b6 from "../../../images/hnm/6/B/6.jpg";
import hnm6b7 from "../../../images/hnm/6/B/7.jpg";
import hnm6b8 from "../../../images/hnm/6/B/8.jpg";

import hnm6c2 from "../../../images/hnm/6/C/2.jpg";
import hnm6c3 from "../../../images/hnm/6/C/3.JPG";
import hnm6c4 from "../../../images/hnm/6/C/4.JPG";
import hnm6c5 from "../../../images/hnm/6/C/5.JPG";
import hnm6c6 from "../../../images/hnm/6/C/6.JPG";

import hnm6d2 from "../../../images/hnm/6/D/2.JPG";
import hnm6d3 from "../../../images/hnm/6/D/6.JPG";
import hnm6d4 from "../../../images/hnm/6/D/4.JPG";
import hnm6d5 from "../../../images/hnm/6/D/5.JPG";
import hnm6d6 from "../../../images/hnm/6/D/6.JPG";
import hnm6d7 from "../../../images/hnm/6/D/7.JPG";
import hnm6d8 from "../../../images/hnm/6/D/8.JPG";
import hnm6d9 from "../../../images/hnm/6/D/9.JPG";
import hnm6d10 from "../../../images/hnm/6/D/10.JPG";
import hnm6d11 from "../../../images/hnm/6/D/11.JPG";
import hnm6d12 from "../../../images/hnm/6/D/12.JPG";
import hnm6d13 from "../../../images/hnm/6/D/13.JPG";
import hnm6d14 from "../../../images/hnm/6/D/14.JPG";
import hnm6d15 from "../../../images/hnm/6/D/15.JPG";
import hnm6d16 from "../../../images/hnm/6/D/16.JPG";
import hnm6d17 from "../../../images/hnm/6/D/17.JPG";
import hnm6d18 from "../../../images/hnm/6/D/18.JPG";
import hnm6d19 from "../../../images/hnm/6/D/19.jpg";
import hnm6d20 from "../../../images/hnm/6/D/20.jpg";
import hnm6d21 from "../../../images/hnm/6/D/21.jpg";
import hnm6d22 from "../../../images/hnm/6/D/22.jpg";
import hnm6d23 from "../../../images/hnm/6/D/23.jpg";
import hnm6d24 from "../../../images/hnm/6/D/24.jpg";
import hnm6d25 from "../../../images/hnm/6/D/25.JPG";

import hnm6e2 from "../../../images/hnm/6/E/2.jpg";
import hnm6e3 from "../../../images/hnm/6/E/3.jpg";
import hnm6e4 from "../../../images/hnm/6/E/4.jpg";
import hnm6e5 from "../../../images/hnm/6/E/5.jpg";
import hnm6e6 from "../../../images/hnm/6/E/6.JPG";
import hnm6e7 from "../../../images/hnm/6/E/7.JPG";
import hnm6e8 from "../../../images/hnm/6/E/8.JPG";
import hnm6e9 from "../../../images/hnm/6/E/9.JPG";
import hnm6e10 from "../../../images/hnm/6/E/10.JPG";
import hnm6e11 from "../../../images/hnm/6/E/11.JPG";
import hnm6e12 from "../../../images/hnm/6/E/12.JPG";
import hnm6e13 from "../../../images/hnm/6/E/13.JPG";
import hnm6e14 from "../../../images/hnm/6/E/14.JPG";
import hnm6e15 from "../../../images/hnm/6/E/15.JPG";
import hnm6e16 from "../../../images/hnm/6/E/16.JPG";
import hnm6e17 from "../../../images/hnm/6/E/17.JPG";
import hnm6e18 from "../../../images/hnm/6/E/18.JPG";
import hnm6e19 from "../../../images/hnm/6/E/19.JPG";
import hnm6e20 from "../../../images/hnm/6/E/20.JPG";
import hnm6e21 from "../../../images/hnm/6/E/21.JPG";
import hnm6e22 from "../../../images/hnm/6/E/22.JPG";
import hnm6e23 from "../../../images/hnm/6/E/23.JPG";
import hnm6e24 from "../../../images/hnm/6/E/24.JPG";
import hnm6e25 from "../../../images/hnm/6/E/25.JPG";
import hnm6e26 from "../../../images/hnm/6/E/26.JPG";
import hnm6e27 from "../../../images/hnm/6/E/27.JPG";
import hnm6e28 from "../../../images/hnm/6/E/28.JPG";
import hnm6e29 from "../../../images/hnm/6/E/29.JPG";
import hnm6e30 from "../../../images/hnm/6/E/30.JPG";
import hnm6e31 from "../../../images/hnm/6/E/31.JPG";
import hnm6e32 from "../../../images/hnm/6/E/32.jpg";
import hnm6e33 from "../../../images/hnm/6/E/33.jpg";
import hnm6e34 from "../../../images/hnm/6/E/34.jpg";
import hnm6e35 from "../../../images/hnm/6/E/35.jpg";
import hnm6e36 from "../../../images/hnm/6/E/36.jpg";
import hnm6e37 from "../../../images/hnm/6/E/37.jpg";
import hnm6e38 from "../../../images/hnm/6/E/38.jpg";

import hnm6f2 from "../../../images/hnm/6/F/2.jpeg";
import hnm6f3 from "../../../images/hnm/6/F/3.jpeg";
import hnm6f4 from "../../../images/hnm/6/F/4.jpeg";
import hnm6f5 from "../../../images/hnm/6/F/5.jpeg";
import hnm6f6 from "../../../images/hnm/6/F/6.jpeg";
import hnm6f7 from "../../../images/hnm/6/F/7.jpeg";
import hnm6f8 from "../../../images/hnm/6/F/8.jpeg";
import hnm6f9 from "../../../images/hnm/6/F/9.jpeg";
import hnm6f10 from "../../../images/hnm/6/F/10.jpeg";
import hnm6f11 from "../../../images/hnm/6/F/11.jpeg";
import hnm6f12 from "../../../images/hnm/6/F/12.jpeg";
import hnm6f13 from "../../../images/hnm/6/F/13.jpeg";
import hnm6f14 from "../../../images/hnm/6/F/14.jpeg";
import hnm6f15 from "../../../images/hnm/6/F/15.jpeg";
import hnm6f16 from "../../../images/hnm/6/F/16.jpeg";
import hnm6f17 from "../../../images/hnm/6/F/17.jpeg";
import hnm6f18 from "../../../images/hnm/6/F/18.jpeg";
import hnm6f19 from "../../../images/hnm/6/F/19.jpeg";
import hnm6f20 from "../../../images/hnm/6/F/20.jpeg";
import hnm6f21 from "../../../images/hnm/6/F/21.jpeg";
import hnm6f22 from "../../../images/hnm/6/F/22.jpeg";
import hnm6f23 from "../../../images/hnm/6/F/23.jpeg";

import hnm6g2 from "../../../images/hnm/6/G/2.jpg";
import hnm6g3 from "../../../images/hnm/6/G/3.jpg";
import hnm6g4 from "../../../images/hnm/6/G/4.jpg";
import hnm6g5 from "../../../images/hnm/6/G/5.jpg";
import hnm6g6 from "../../../images/hnm/6/G/6.jpg";
import hnm6g7 from "../../../images/hnm/6/G/7.jpeg";
import hnm6g8 from "../../../images/hnm/6/G/8.jpg";
import hnm6g9 from "../../../images/hnm/6/G/9.jpg";
import hnm6g10 from "../../../images/hnm/6/G/10.jpeg";
import hnm6g11 from "../../../images/hnm/6/G/11.jpeg";
import hnm6g12 from "../../../images/hnm/6/G/12.jpeg";
import hnm6g13 from "../../../images/hnm/6/G/13.JPEG";
import hnm6g14 from "../../../images/hnm/6/G/14.jpeg";
import hnm6g15 from "../../../images/hnm/6/G/15.jpeg";

import hnm6h2 from "../../../images/hnm/6/H/2.JPG";
import hnm6h3 from "../../../images/hnm/6/H/3.JPG";
import hnm6h4 from "../../../images/hnm/6/H/4.JPG";
import hnm6h5 from "../../../images/hnm/6/H/5.JPG";
import hnm6h6 from "../../../images/hnm/6/H/6.JPG";
import hnm6h7 from "../../../images/hnm/6/H/7.JPG";
import hnm6h8 from "../../../images/hnm/6/H/8.JPG";
import hnm6h9 from "../../../images/hnm/6/H/9.JPG";
import hnm6h10 from "../../../images/hnm/6/H/10.JPG";
import hnm6h11 from "../../../images/hnm/6/H/11.JPG";
import hnm6h12 from "../../../images/hnm/6/H/12.JPG";
import hnm6h13 from "../../../images/hnm/6/H/13.JPG";
import hnm6h14 from "../../../images/hnm/6/H/14.JPG";
import hnm6h15 from "../../../images/hnm/6/H/15.JPG";
import hnm6h16 from "../../../images/hnm/6/H/16.JPG";
import hnm6h17 from "../../../images/hnm/6/H/17.JPG";
import hnm6h18 from "../../../images/hnm/6/H/18.JPG";
import hnm6h19 from "../../../images/hnm/6/H/19.JPG";
import hnm6h20 from "../../../images/hnm/6/H/20.JPG";
import hnm6h21 from "../../../images/hnm/6/H/21.JPG";
import hnm6h22 from "../../../images/hnm/6/H/22.JPG";
import hnm6h23 from "../../../images/hnm/6/H/23.JPG";
import hnm6h24 from "../../../images/hnm/6/H/24.JPG";
import hnm6h25 from "../../../images/hnm/6/H/25.JPG";
import hnm6h26 from "../../../images/hnm/6/H/26.JPG";
import hnm6h27 from "../../../images/hnm/6/H/27.JPG";
import hnm6h28 from "../../../images/hnm/6/H/28.JPG";
import hnm6h29 from "../../../images/hnm/6/H/29.JPG";
import hnm6h30 from "../../../images/hnm/6/H/30.JPG";
import hnm6h31 from "../../../images/hnm/6/H/31.JPG";
import hnm6h32 from "../../../images/hnm/6/H/32.JPG";
import hnm6h33 from "../../../images/hnm/6/H/33.JPG";
import hnm6h34 from "../../../images/hnm/6/H/34.JPG";
import hnm6h35 from "../../../images/hnm/6/H/35.JPG";
import hnm6h36 from "../../../images/hnm/6/H/36.JPG";
import hnm6h37 from "../../../images/hnm/6/H/37.JPG";
import hnm6h38 from "../../../images/hnm/6/H/38.JPG";
import hnm6h39 from "../../../images/hnm/6/H/39.JPG";
import hnm6h40 from "../../../images/hnm/6/H/40.JPG";
import hnm6h41 from "../../../images/hnm/6/H/41.JPG";
import hnm6h42 from "../../../images/hnm/6/H/42.JPG";
import hnm6h43 from "../../../images/hnm/6/H/43.JPG";
import hnm6h44 from "../../../images/hnm/6/H/44.JPG";
import hnm6h45 from "../../../images/hnm/6/H/45.JPG";
import hnm6h46 from "../../../images/hnm/6/H/46.JPG";
import hnm6h47 from "../../../images/hnm/6/H/47.JPG";
import hnm6h48 from "../../../images/hnm/6/H/48.JPG";
import hnm6h49 from "../../../images/hnm/6/H/49.JPG";
import hnm6h50 from "../../../images/hnm/6/H/50.JPG";
import hnm6h51 from "../../../images/hnm/6/H/51.JPG";
import hnm6h52 from "../../../images/hnm/6/H/52.JPG";

import hnm6i2 from "../../../images/hnm/6/I/2.JPG";
import hnm6i4 from "../../../images/hnm/6/I/4.JPG";
import hnm6i3 from "../../../images/hnm/6/I/3.JPG";
import hnm6i5 from "../../../images/hnm/6/I/5.JPG";
import hnm6i6 from "../../../images/hnm/6/I/6.JPG";
import hnm6i7 from "../../../images/hnm/6/I/7.JPG";

import hnm6j2 from "../../../images/hnm/6/J/2.JPG";
import hnm6j3 from "../../../images/hnm/6/J/3.JPG";
import hnm6j4 from "../../../images/hnm/6/J/4.JPG";
import hnm6j5 from "../../../images/hnm/6/J/5.JPG";
import hnm6j6 from "../../../images/hnm/6/J/6.JPG";
import hnm6j7 from "../../../images/hnm/6/J/7.JPG";
import hnm6j8 from "../../../images/hnm/6/J/8.JPG";
import hnm6j9 from "../../../images/hnm/6/J/9.JPG";
import hnm6j10 from "../../../images/hnm/6/J/10.JPG";
import hnm6j11 from "../../../images/hnm/6/J/11.JPG";

import hnm6K2 from "../../../images/hnm/6/K/2.JPG";
import hnm6K3 from "../../../images/hnm/6/K/6.JPG";
import hnm6K4 from "../../../images/hnm/6/K/4.JPG";
import hnm6K5 from "../../../images/hnm/6/K/5.JPG";
import hnm6K6 from "../../../images/hnm/6/K/6.JPG";
import hnm6K7 from "../../../images/hnm/6/K/7.JPG";
import hnm6K8 from "../../../images/hnm/6/K/8.JPG";
import hnm6K9 from "../../../images/hnm/6/K/9.JPG";
import hnm6K10 from "../../../images/hnm/6/K/10.JPG";
import hnm6K11 from "../../../images/hnm/6/K/11.JPG";
import hnm6K12 from "../../../images/hnm/6/K/12.JPG";
import hnm6K13 from "../../../images/hnm/6/K/13.JPG";
import hnm6K14 from "../../../images/hnm/6/K/14.JPG";
import hnm6K15 from "../../../images/hnm/6/K/15.JPG";
import hnm6K16 from "../../../images/hnm/6/K/16.JPG";
import hnm6K17 from "../../../images/hnm/6/K/17.JPG";
import hnm6K18 from "../../../images/hnm/6/K/18.JPG";
import hnm6K19 from "../../../images/hnm/6/K/19.JPG";
import hnm6K20 from "../../../images/hnm/6/K/20.JPG";
import hnm6K21 from "../../../images/hnm/6/K/21.JPG";
import hnm6K22 from "../../../images/hnm/6/K/22.JPG";

import hnm6L2 from "../../../images/hnm/6/L/2.JPG";
import hnm6L3 from "../../../images/hnm/6/L/6.JPG";
import hnm6L4 from "../../../images/hnm/6/L/4.JPG";
import hnm6L5 from "../../../images/hnm/6/L/5.JPG";
import hnm6L6 from "../../../images/hnm/6/L/6.JPG";
import hnm6L7 from "../../../images/hnm/6/L/7.JPG";
import hnm6L8 from "../../../images/hnm/6/L/8.JPG";
import hnm6L9 from "../../../images/hnm/6/L/9.JPG";
import hnm6L10 from "../../../images/hnm/6/L/10.JPG";
import hnm6L11 from "../../../images/hnm/6/L/11.JPG";
import hnm6L12 from "../../../images/hnm/6/L/12.JPG";
import hnm6L13 from "../../../images/hnm/6/L/13.JPG";
import hnm6L14 from "../../../images/hnm/6/L/14.JPG";
import hnm6L15 from "../../../images/hnm/6/L/15.JPG";
import hnm6L16 from "../../../images/hnm/6/L/16.JPG";
import hnm6L17 from "../../../images/hnm/6/L/17.JPG";
import hnm6L18 from "../../../images/hnm/6/L/18.JPG";

require("dotenv").config();

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      images: [photo1, photo2],
      photoIndex: 0,
      services: [
        {
          title: "Collision",
          sub: [
            {
              title: "CONTAINER VESSEL – MAJOR COLLISION",
              image: one_a,
              images: [one_a, hnm1a2, hnm1a2],
            },
            {
              title: "CONTAINER VESSSEL – MAJOR COLLISION",
              image: one_b,
              images: [one_b, hnm1b2],
            },
            {
              title: "CONTAINER VESSEL – MOVING COLLISION",
              image: one_c,
              images: [one_c, hnm1c2, hnm1c3, hnm1c4, hnm1c5],
            },
            {
              title: "OFFSHORE PLATFORM – FIX FLOATING OBJECT COLLISION",
              image: one_d,
              images: [one_d, hnm1d2, hnm1d3, hnm1d4, hnm1d5, hnm1d6],
            },
            {
              title: "CONTAINER VESSEL – MAJOR COLLISION",
              image: one_e,
              images: [one_e, hnm1e2, hnm1e3, hnm1e4],
            },
            {
              title: "OIL TANKER – MAJOR COLLISION",
              image: one_f,
              images: [one_f, hnm1f2, hnm1f3, hnm1f4, hnm1f5],
            },
          ],
        },
        {
          title: "Engine damage ",
          sub: [
            {
              title: "CONTAINER VESSEL – CRANKSHAFT DAMAGE",
              image: two_a,
              images: [two_a, hnm2a2, hnm2a3, hnm2a4],
            },
            {
              title: "SUPPLY VESSEL – ME DAMAGE FIRE",
              image: two_b,
              images: [two_b, hnm2b2, hnm2b3, hnm2b4, hnm2b5],
            },
            {
              title: "CONTAINER VESSEL – AE CRANKSHAFT & ENGINE BLOCK BROKE",
              image: two_c,
              images: [two_c, hnm2c2, hnm2c3, hnm2c4, hnm2c5],
            },
            {
              title: "CEMENT CARRIER – TURBOCHARGER DAMAGE",
              image: two_d,
              images: [two_d, hnm2d2, hnm2d3, hnm2d4],
            },
          ],
        },
        {
          title: "Fire onboard",
          sub: [
            {
              title: "FLAT TOP BARGE – EXPLOTION IN VOID TANK",
              image: three_a,
              images: [
                three_a,
                hnm3a2,
                hnm3a3,
                hnm3a4,
                hnm3a5,
                hnm3a6,
                hnm3a7,
                hnm3a8,
              ],
            },
            {
              title: "GENERAL CARGO – FIRE IN CARGO HOLD",
              image: three_b,
              images: [
                three_b,
                hnm3b2,
                hnm3b3,
                hnm3b4,
                hnm3b5,
                hnm3b6,
                hnm3b7,
                hnm3b8,
                hnm3b9,
                hnm3b10,
                hnm3b11,
                hnm3b12,
                hnm3b13,
                hnm3b14,
                hnm3b15,
                hnm3b16,
                hnm3b17,
                hnm3b18,
                hnm3b19,
              ],
            },
            {
              title: "GENERAL CARGO – FIRE BROKE OUT IN ENGINE ROOM",
              image: three_c,
              images: [
                three_c,
                hnm3c2,
                hnm3c3,
                hnm3c4,
                hnm3c5,
                hnm3c6,
                hnm3c7,
                hnm3c8,
                hnm3c9,
                hnm3c10,
                hnm3c11,
                hnm3c12,
                hnm3c13,
              ],
            },
            {
              title: "FPSO – FIRE BROKE OUT IN ENGINE ROOM",
              image: three_d,
              images: [three_d, hnm3d2, hnm3d3, hnm3d4, hnm3d5, hnm3d6, hnm3d7],
            },
            {
              title:
                "CONTAINER VESSEL – FIRE BROKE OUT IN ENGINE ROOM & ACCOMMODATION SPACE",
              image: three_e,
              images: [
                three_e,
                hnm3e2,
                hnm3e3,
                hnm3e4,
                hnm3e5,
                hnm3e6,
                hnm3e7,
                hnm3e8,
                hnm3e9,
                hnm3e10,
                hnm3e11,
                hnm3e12,
                hnm3e13,
                hnm3e14,
                hnm3e15,
                hnm3e16,
                hnm3e17,
                hnm3e18,
                hnm3e19,
                hnm3e20,
                hnm3e21,
                hnm3e22,
                hnm3e23,
                hnm3e24,
                hnm3e25,
                hnm3e26,
                hnm3e27,
                hnm3e28,
                hnm3e29,
              ],
            },
            {
              title: "TANKER – EXPLOTION IN ENGINE ROOM",
              image: three_f,
              images: [
                three_f,
                hnm3f2,
                hnm3f3,
                hnm3f4,
                hnm3f5,
                hnm3f6,
                hnm3f7,
                hnm3f8,
                hnm3f9,
                hnm3f10,
                hnm3f11,
                hnm3f12,
                hnm3f13,
                hnm3f14,
                hnm3f15,
                hnm3f16,
                hnm3f17,
                hnm3f18,
                hnm3f19,
                hnm3f20,
                hnm3f21,
                hnm3f22,
                hnm3f23,
                hnm3f24,
              ],
            },
            {
              title: "DREDGER WORK BARGE – FIRE IN CARGO EQUIPMENT ROOM",
              image: three_g,
              images: [
                three_g,
                hnm3g2,
                hnm3g3,
                hnm3g4,
                hnm3g5,
                hnm3g6,
                hnm3g7,
                hnm3g8,
                hnm3g9,
                hnm3g10,
                hnm3g11,
              ],
            },
            {
              title: "TUGBOAT – FIRE BROKE OUT IN ENGINE ROOM",
              image: three_h,
              images: [three_h, hnm3h2, hnm3h3, hnm3h4, hnm3h5, hnm3h6, hnm3h7],
            },
          ],
        },
        {
          title: "General average survey",
          sub: [
            {
              title: "CONTAINER VESSEL – GENERAL AVERAGE SURVEY",
              image: fourth_a,
              images: [fourth_a],
            },
          ],
        },
        {
          title: "Crane damage",
          sub: [
            {
              title: "CONTAINER VESSEL – DECK CRANE COLLAPSED",
              image: five_a,
              images: [five_a, hnm5a2, hnm5a3, hnm5a4, hnm5a5, hnm5a6],
            },
            {
              title: "BULK CARRIER – CRANE BOOM FELL ONTO CARGO HOLD",
              image: five_b,
              images: [five_b, hnm5b2, hnm5b3, hnm5b4, hnm5b5],
            },
            {
              title: "BULK CARRIER – CRANE BOOM FELL ON DECK",
              image: five_c,
              images: [five_c, hnm5c2, hnm5c3, hnm5c4],
            },
          ],
        },
        {
          title: "Survey on Connection with Salvage of ship ",
          sub: [
            {
              title: "LCT – STRANDED ON ROCKY BEACH",
              image: six_a,
              images: [
                six_a,
                hnm6a2,
                hnm6a3,
                hnm6a4,
                hnm6a5,
                hnm6a6,
                hnm6a7,
                hnm6a8,
                hnm6a9,
                hnm6a10,
                hnm6a11,
                hnm6a12,
                hnm6a13,
              ],
            },
            {
              title: "GENERAL CARGO – HEAVILY LISTED AND CAPSIZED",
              image: six_b,
              images: [
                six_b,
                hnm6b2,
                hnm6b3,
                hnm6b4,
                hnm6b5,
                hnm6b6,
                hnm6b7,
                hnm6b8,
              ],
            },
            {
              title: "PASSENGER VESSEL – TOOK WATER AND SEMI SUBMERGED",
              image: six_c,
              images: [six_c, hnm6c2, hnm6c3, hnm6c4, hnm6c5, hnm6c6],
            },
            {
              title: "CEMENT CARRIER – HIT BY TSUNAMI",
              image: six_d,
              images: [
                six_d,
                hnm6d2,
                hnm6d3,
                hnm6d4,
                hnm6d5,
                hnm6d6,
                hnm6d7,
                hnm6d8,
                hnm6d9,
                hnm6d10,
                hnm6d11,
                hnm6d12,
                hnm6d13,
                hnm6d14,
                hnm6d15,
                hnm6d16,
                hnm6d17,
                hnm6d18,
                hnm6d19,
                hnm6d20,
                hnm6d21,
                hnm6d22,
                hnm6d23,
                hnm6d24,
                hnm6d25,
              ],
            },
            {
              title: "FLAT TOP BARGE – HEAVILY LISTED AND CAPSIZED",
              image: six_e,
              images: [
                six_e,
                hnm6e2,
                hnm6e3,
                hnm6e4,
                hnm6e5,
                hnm6e6,
                hnm6e7,
                hnm6e8,
                hnm6e9,
                hnm6e10,
                hnm6e11,
                hnm6e12,
                hnm6e13,
                hnm6e14,
                hnm6e15,
                hnm6e16,
                hnm6e17,
                hnm6e18,
                hnm6e19,
                hnm6e20,
                hnm6e21,
                hnm6e22,
                hnm6e23,
                hnm6e24,
                hnm6e25,
                hnm6e26,
                hnm6e27,
                hnm6e28,
                hnm6e29,
                hnm6e30,
                hnm6e31,
                hnm6e32,
                hnm6e33,
                hnm6e34,
                hnm6e35,
                hnm6e36,
                hnm6e37,
                hnm6e38,
              ],
            },
            {
              title: "FLAT TOP BARGE – HEAVILY STRANDED ON CLIFF",
              image: six_f,
              images: [
                six_f,
                hnm6f2,
                hnm6f3,
                hnm6f4,
                hnm6f5,
                hnm6f6,
                hnm6f7,
                hnm6f8,
                hnm6f9,
                hnm6f10,
                hnm6f11,
                hnm6f12,
                hnm6f13,
                hnm6f14,
                hnm6f15,
                hnm6f16,
                hnm6f17,
                hnm6f18,
                hnm6f19,
                hnm6f20,
                hnm6f21,
                hnm6f22,
                hnm6f23,
              ],
            },
            {
              title: "FLAT TOP BARGE – PARTED IN MIDSHIP SECTION",
              image: six_g,
              images: [
                six_g,
                hnm6g2,
                hnm6g3,
                hnm6g4,
                hnm6g5,
                hnm6g6,
                hnm6g7,
                hnm6g8,
                hnm6g9,
                hnm6g10,
                hnm6g11,
                hnm6g12,
                hnm6g13,
                hnm6g14,
                hnm6g15,
              ],
            },
            {
              title: "BULK CARRIER – LISTED AND RAN AGROUND",
              image: six_h,
              images: [
                six_h,
                hnm6h2,
                hnm6h3,
                hnm6h4,
                hnm6h5,
                hnm6h6,
                hnm6h7,
                hnm6h8,
                hnm6h9,
                hnm6h10,
                hnm6h11,
                hnm6h12,
                hnm6h13,
                hnm6h14,
                hnm6h15,
                hnm6h16,
                hnm6h17,
                hnm6h18,
                hnm6h19,
                hnm6h20,
                hnm6h21,
                hnm6h22,
                hnm6h23,
                hnm6h24,
                hnm6h25,
                hnm6h26,
                hnm6h27,
                hnm6h28,
                hnm6h29,
                hnm6h30,
                hnm6h31,
                hnm6h32,
                hnm6h33,
                hnm6h34,
                hnm6h35,
                hnm6h36,
                hnm6h37,
                hnm6h38,
                hnm6h39,
                hnm6h40,
                hnm6h41,
                hnm6h42,
                hnm6h43,
                hnm6h44,
                hnm6h45,
                hnm6h46,
                hnm6h47,
                hnm6h48,
                hnm6h49,
                hnm6h50,
                hnm6h51,
                hnm6h52,
              ],
            },
            {
              title: "BULK CARRIER – HIT SUBMERGED OBJECT AND SEMI SUBMERGE",
              image: six_i,
              images: [six_i, hnm6i2, hnm6i3, hnm6i4, hnm6i5, hnm6i6, hnm6i7],
            },
            {
              title: "BULK CARRIER – HIT CLIFF AND STRANDED",
              image: six_j,
              images: [
                six_j,
                hnm6j2,
                hnm6j3,
                hnm6j4,
                hnm6j5,
                hnm6j6,
                hnm6j7,
                hnm6j8,
                hnm6j9,
                hnm6j10,
                hnm6j11,
              ],
            },
            {
              title: "GENERAL CARGO – STRANDED AT CLIFF",
              image: six_k,
              images: [
                six_k,
                hnm6K2,
                hnm6K3,
                hnm6K4,
                hnm6K5,
                hnm6K6,
                hnm6K7,
                hnm6K8,
                hnm6K9,
                hnm6K10,
                hnm6K11,
                hnm6K12,
                hnm6K13,
                hnm6K14,
                hnm6K15,
                hnm6K16,
                hnm6K17,
                hnm6K18,
                hnm6K19,
                hnm6K20,
                hnm6K21,
                hnm6K22,
              ],
            },
            {
              title: "BULK CARRIER – RAN AGROUND",
              image: six_l,
              images: [
                six_l,
                hnm6L2,
                hnm6L3,
                hnm6L4,
                hnm6L5,
                hnm6L6,
                hnm6L7,
                hnm6L8,
                hnm6L9,
                hnm6L10,
                hnm6L11,
                hnm6L12,
                hnm6L13,
                hnm6L14,
                hnm6L15,
                hnm6L16,
                hnm6L17,
                hnm6L18,
              ],
            },
          ],
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.title = "Abadi Cemerlang - Hull and Machinery";
  }

  handleChange(e, prop) {
    this.setState({
      [prop]: e.target.value,
    });
  }

  handleOpen = (item) => {
    this.setState({
      isOpen: true,
      imagesActive: item,
    });
  };

  handleClose = () => {
    this.setState({
      showDialog: false,
    });
  };

  handleGo = (link) => {
    history.push(link);
  };

  render() {
    return (
      <div>
        <section className="section-banner service hnm">
          <div className="container">
            <div className="row ">
              <div className="col-md-10">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                  <div className="content text-left">
                    <h1>HULL & MACHINERY</h1>
                    <p>
                      We offer our clients an extensive Hull & Machinery service
                      supported by our combined marine industry expertise. Our
                      extensive experience with local and international H&M
                      underwriters enables us to manage each and every step of
                      all Hull & Machinery claims.{" "}
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="section-services">
          {this.state.services.map((row, id) => (
            <div className="container" key={id}>
              <div className="title-wrap mt-3 text-center text-uppercase">
                <h2>{row.title}</h2>
                <div className="border-line ml-auto"> </div>
              </div>

              <div className="row ">
                {row.sub.map(
                  function (item, idx) {
                    return (
                      <div
                        className="col-md-4"
                        key={idx}
                        onClick={() =>
                          this.handleOpen(item.images ? item.images : [])
                        }
                      >
                        <div
                          className="card-image mb-2"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                        <div className="detail box">
                          <label>{item.title}</label>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )}
              </div>
            </div>
          ))}
          {this.state.isOpen && (
            <Lightbox
              mainSrc={this.state.imagesActive[this.state.photoIndex]}
              nextSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + 1) % this.state.imagesActive.length
                ]
              }
              prevSrc={
                this.state.imagesActive[
                  (this.state.photoIndex + this.state.imagesActive.length - 1) %
                    this.state.imagesActive.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.imagesActive.length -
                      1) %
                    this.state.imagesActive.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.imagesActive.length,
                })
              }
            />
          )}
          <AnimationOnScroll
            initiallyVisible={false}
            delay={500}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="row ">
              {this.state.services.map((row, id) => (
                <div className="col-md-4" key={id}>
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${row.image})` }}
                  >
                    <div className="detail box">
                      <h3>{row.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AnimationOnScroll>
          {/*</div>*/}
        </section>
      </div>
    );
  }
}

export default Services;
